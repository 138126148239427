import { lazy } from "react";
import { AuthLayout, BasicLayout, NoLayout } from "@ccb/components";

const Container = lazy(() => import("../components/Container/Container"));
const Components = lazy(() => import("../pages/Components/Components"));
const Partners = lazy(() => import("../pages/Partners/Partners"));
const SearchPartners = lazy(() => import("../pages/Partner/Partner"));
const EditPartner = lazy(() => import("../pages/Partner/Edit"));
const MyOrderRequests = lazy(() => import("../pages/Partner/MyOrderRequests"));
const MyTransfers = lazy(() => import("../pages/Partner/MyTransfers"));
const MyWalletTransactions = lazy(() => import("../pages/Partner/MyWalletTransactions"));
const MySearchTransactions = lazy(() => import("../pages/Partner/MySearchTransactions"));
const MyHierarchy = lazy(() => import("../pages/Partner/MyHierarchy"));
const MyLeadPartners = lazy(() => import("../pages/Partner/MyLeadPartners"));
const MyReports = lazy(() => import("../pages/Partner/MyReports"));
const MainContent = lazy(() => import("../components/MainContent/MainContent"));
const ManageLoggedInUser = lazy(() => import("../pages/Partner/ManageMyAccount"));
const Login = lazy(() => import("../pages/Login/Login"));
const ForgotPassword = lazy(() => import("../pages/Login/ForgotPassword"));
const ResetPassword = lazy(() => import("../pages/Login/ResetPassword"));

/* const Header = lazy(() => import("../components/Header/Header"));
const BasicHeader = lazy(() => import("../components/BasicHeader/BasicHeader"));
const SideNav = lazy(() => import("../components/SideNav/SideNav"));
const Dashboard = lazy(() => import("../pages/Dashboard/Dashboard"));
const ManagePartner = lazy(() => import("../pages/Partner/ManagePartner")); */

const userPermissions = sessionStorage.getItem('userPermissions');

const getRoutes = () => {
  const routesList = {
    components:
    {
      index: 'components',
      key: "router-components",
      title: "Header",
      path: "/components",
      enabled: false,
      component: Components,
      auth: false,
      layout: BasicLayout,
      header: "Header"
    },
    login:
    {
      index: 'login',
      key: "router-login",
      title: "Header",
      path: "/login",
      enabled: false,
      component: Login,
      auth: false,
      layout: NoLayout,
    },
    lostpassword:
    {
      index: 'lostpassword',
      key: "router-lostpassword",
      title: "Header",
      path: "/passwordlost",
      enabled: false,
      component: ForgotPassword,
      auth: false,
      layout: NoLayout,
    },
    resetPassword:
    {
      index: 'resetPassword',
      key: "router-resetPassword",
      title: "Header",
      path: "/resetpassword",
      enabled: false,
      component: ResetPassword,
      auth: false,
      layout: NoLayout,
    },
    partnersInfo:
    {
      index: 'partners',
      key: "router-partners",
      title: "Partners",
      path: "/partners/:id/:tabId",
      enabled: false,
      component: Partners,
      icon: "PartnersIcon",
      auth: true,
      layout: AuthLayout,
      container: Container,
      mainContent: MainContent,
      breadcrumbs: ["home", "Partner Management"],
    },
    partner:
    {
      index: 'partner',
      key: "router-partner",
      title: "Partners",
      path: "/searchPartners",
      enabled: true,
      component: SearchPartners,
      auth: true,
      layout: AuthLayout,
      container: Container,
      mainContent: MainContent,
      header: "Header",
      icon: "PartnersIcon",
      breadcrumbs: ['home']
    },
    newPartner:
    {
      index: 'newPartner',
      key: "router-new-components",
      title: "Create Partner",
      path: "/partners/addPartner",
      enabled: false,
      component: EditPartner,
      mainContent: MainContent,
      auth: true,
      layout: AuthLayout,
      icon: "NewPartnerIcon",
      header: "Header",
      container: Container,
      breadcrumbs: ['home']
    },
    managePartner:
    {
      index: 'managePartner',
      key: "router-manage-components",
      title: "Manage Partner",
      path: "/partners/managePartner/:id",
      enabled: false,
      component: EditPartner,
      mainContent: MainContent,
      auth: true,
      layout: AuthLayout,
      header: "Header",
      container: Container,
      breadcrumbs: ['home']
    },
    manageAccount:
    {
      index: 'manage-my-account',
      key: "router-manage-my-account",
      title: "Manage Account",
      path: "/manageMyAccount",
      enabled: false,
      component: ManageLoggedInUser,
      mainContent: MainContent,
      auth: true,
      layout: AuthLayout,
      header: "Header",
      container: Container,
      breadcrumbs: ["Partner Management"]
    },
    partnerOrderRequests:
    {
      index: 'myPartnerOrderRequests_idx',
      key: "router-partner-myorder-requests",
      title: "Order Requests",
      path: "/partners/myOrderRequests",
      enabled: false,
      component: MyOrderRequests,
      mainContent: MainContent,
      auth: true,
      layout: AuthLayout,
      icon: "PartnerOrderRequests",
      header: "Header",
      container: Container,
      breadcrumbs: ['home']
    },
    transfers:
    {
      index: 'myTransfers_idx',
      key: "router-partner-mytransfers",
      title: "Transfers",
      path: "/partners/myTransfers",
      enabled: false,
      component: MyTransfers,
      mainContent: MainContent,
      auth: true,
      layout: AuthLayout,
      icon: "TransferArrows",
      header: "Header",
      container: Container,
      breadcrumbs: ['home']
    },
    walletAdjustments:
    {
      index: 'myWalletAdjustments_idx',
      key: "router-partner-myWalletAdjustments",
      title: "Wallet Adjustments",
      path: "/partners/myWalletAdjustments",
      enabled: false,
      component: MyWalletTransactions,
      mainContent: MainContent,
      auth: true,
      layout: AuthLayout,
      icon: "MyWalletAdjustments",
      header: "Header",
      container: Container,
      breadcrumbs: ['home']
    },
    searchTransaction:
    {
      index: 'mySearchTransactions_idx',
      key: "router-partner-mySearchTransactions",
      title: "Search Transactions",
      path: "/partners/mySearchTransactions",
      enabled: false,
      component: MySearchTransactions,
      mainContent: MainContent,
      auth: true,
      layout: AuthLayout,
      icon: "MyTransactionsSearch",
      header: "Header",
      container: Container,
      breadcrumbs: ['home']
    },
    hierarchy:
    {
      index: 'myHierarchy_idx',
      key: "router-partner-myhierarchy",
      title: "Hierarchy",
      path: "/partners/myhierarchy",
      enabled: false,
      component: MyHierarchy,
      mainContent: MainContent,
      auth: true,
      layout: AuthLayout,
      icon: "Hierarchy",
      header: "Header",
      container: Container,
      breadcrumbs: ['home']
    },
    leadpartners:
    {
      index: 'myLeadPartners_idx',
      key: "router-partner-myleadpartners",
      title: "Lead",
      path: "/partners/leadpartners",
      enabled: true,
      component: MyLeadPartners,
      mainContent: MainContent,
      auth: true,
      layout: AuthLayout,
      icon: "LeadIcon",
      header: "Header",
      container: Container,
      breadcrumbs: ['home']
    },
    reports:
    {
      index: 'myReports_idx',
      key: "router-partner-myreports",
      title: "Reports",
      path: "/partners/myreports",
      enabled: false,
      component: MyReports,
      mainContent: MainContent,
      auth: true,
      layout: AuthLayout,
      icon: "Reports",
      header: "Header",
      container: Container,
      breadcrumbs: ['home']
    },
  };
  if (userPermissions) {
    (JSON.parse(userPermissions).tabs).forEach((permission) => {
      if (permission.tabKey in routesList) {
        routesList[permission.tabKey].enabled = true;
      }
    })
  }
  return Object.values(routesList);
};
export const routes = getRoutes();
