import {Typography} from '@material-ui/core';

const EVTypography = (props) => {
    const {children, variant, component, ...rest} = props;
	return (
        <Typography variant={variant} component={component} {...rest}>
            {children}
        </Typography>
    )
}
export default EVTypography;
