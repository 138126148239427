import React from "react";

const SideNav = (props) => {
  return (
    <>
      {props.children}
    </>
  );
};

export default SideNav;
