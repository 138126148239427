const CryptoJS = require('crypto-js');
// const service = require('../services/serviceHelper');
const {REACT_APP_AES_KEY} = require('../constants');
/* eslint-disable no-mixed-operators */

const getCookieValue = (cookie, a) => {
  if (cookie !== undefined) {
    // eslint-disable-next-line prefer-template
    const b = cookie.match('(^|;)\\s*' + a + '\\s*=\\s*([^;]+)');
    return b ? b.pop() : '';
  }
  return undefined;
};

const cryptoJSEncryptAES = (data, isString = false) => {
  if (!data) {
    return null;
  }
  const aesKey = REACT_APP_AES_KEY;
  const parsedBase64Key = CryptoJS.enc.Base64.parse(aesKey);
  const encryptedData = CryptoJS.AES.encrypt(
    isString ? data : JSON.stringify(data),
    parsedBase64Key,
    {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    },
  );
  return encryptedData.toString();
};

const cryptoJSDecryptAES = (data, isString = false) => {
  if (!data) {
    return null;
  }
  const aesKey = REACT_APP_AES_KEY;
  const parsedBase64Key = CryptoJS.enc.Base64.parse(aesKey);

  const decryptedData = CryptoJS.AES.decrypt(isString ? data : data.data, parsedBase64Key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });
  return JSON.parse(CryptoJS.enc.Utf8.stringify(decryptedData));
};

const callBack = (request, response, secure, promise) =>
  promise.then(
    (res) => {
      let obj;
      if (secure) {
        obj = {
          data: cryptoJSEncryptAES(res),
        };
      } else {
        obj = res;
      }
      response.json(obj);
    },
    (err) => {
      if (err && err.status) {
        response.status(err.status);
      }
      if (err.status === 401) {
        // service.apiService.refreshToken(request, response);
      } else {
        response.json(err);
      }
    },
  );

 const convertMillSecondsToDateTimeString = (date) => {
  if (date < 0) {
    return '--';
  } else if (date) {
    const selectedDate = new Date(parseInt(date, 10)).toISOString();
    const YYYY = selectedDate.substring(0, 4).replace('T', ' ');
    const MM = selectedDate.substring(5, 7).replace('T', ' ');
    const DD = selectedDate.substring(8, 10).replace('T', ' ');
    return `${MM}/${DD}/${YYYY}`;
  }
  return '--';
};

const phoneNumberFromAPI = (phoneString, removeString) => {
  if (phoneString?.length > 0) {
    const phoneCodeWithoutPlus = removeString?.replace("+", "");
    const index = phoneString.indexOf(phoneCodeWithoutPlus);
    if (index !== -1 && index === 0) {
      return (
        phoneString.substring(0, index) +
        "" +
        phoneString.substring(index + phoneCodeWithoutPlus.length)
      );
    } else {
      return phoneString;
    }
  } else return "";
};

const phoneNumberToAPI = (phoneString, appendString, removeString="") => {
  if (phoneString?.length > 0) {
    const removedString = appendString?.replace(removeString, "");
    return `${removedString}${phoneString}`;
  } else return "";
};

const areAllCharactersDigits = (inputString) => {
  for (let position = 0; position < inputString.length; position++) {
    if (inputString[position] < '0' || inputString[position] > '9') {
      return false;
    }
  }
  return true;
}

const onlyDigits = (fieldName, fieldValue='', setFieldValue)=> {
  if(fieldValue.length <=12 && areAllCharactersDigits(fieldValue)) {
    setFieldValue(fieldName, fieldValue)
  }
}

module.exports = {
  cryptoJSEncryptAES,
  cryptoJSDecryptAES,
  getCookieValue,
  callBack,
  convertMillSecondsToDateTimeString,
  phoneNumberFromAPI,
  phoneNumberToAPI,
  areAllCharactersDigits,
  onlyDigits
};
