import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { AppBar } from "@material-ui/core";
import { Grid } from "@ccb/components/src";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
  })
);

const Header = (props) => {
  const classes = useStyles();

  return (
    <Grid className={classes.root}>
      <AppBar position="fixed" color="inherit" elevation={0}>
        {props.header}
      </AppBar>
    </Grid>
  );
};

export default Header;
