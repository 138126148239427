import React from 'react';
import Chips from '@mui/material/Chip';
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles(theme => ({
  chips: {
    '& .MuiChip-label': {
      '&:hover': {
        cursor: 'pointer',
      },
    },
  },
}));

const Chip = ({ label, variant = 'outlined', clickHandler, deleteHandler, size = 'small', color = 'default', className = "" }) => {
  const classes = useStyles();
  return (
    <Chips
      label={label}
      variant={variant}
      size={size}
      color={color}
      onClick={clickHandler}
      onDelete={deleteHandler}
      className={className.concat(classes.chips)}
    />
  );
};

export default Chip;



