import { createTheme } from "@material-ui/core/styles";
import "@material-ui/core/styles";

function pxToRem(value) {
  return `${value / 16}rem`;
}

const EVColors = {
  white: "#FFFFFF",
  offWhite: "#FAFCFF",
  black: "#000000",
  offBlack: "#0E0F11",
  lightBlack: "#cccccc",

  //Grey
  greyDark: "#6F7073",
  greyLight: "#ECEDEC",
  greyFaint: "#F5F5F5",
  grey: "#D8D9D8",

  //stepper color
  stepperGreyLite: "#D9D9D9",

  //status color
  statusBlue: "#B3E3FF",
  statusLightBlue: "#CCF3FF",
  statusTeal: "#D9FBEF",
  statusGreen: "#E0F5C4",
  statusPurple: "#F4E5FF",
  statusYellow: "#FFE7B8",

  formLabelColor: "#4D4D4D",
  textColor: "#000000",
  //authBgColor
  bgColor: "#F6F7FB",

  //dashboard card color
  darkBlue: "#1B183F",
  green: "#56C314",
  orange: "#FE4E03",
};

const theme = createTheme({
  spacing: 4,
  shadows: [
    "none",
    "0px 0px 3px rgba(0, 0, 0, 0.06), 0px 8px 12px rgba(0, 0, 0, 0.03), 0px 0px 24px rgba(0, 0, 0, 0.04)",
    "0px 0px 0px rgba(0, 0, 0, 0.12), 0px 4px 8px rgba(0, 0, 0, 0.03), 0px 0px 16px rgba(0, 0, 0, 0.08)",
    "0px 8px 12px 0px rgba(0, 0, 0, 0.03)",
    "none",
    "none",
    "none",
    "none",
    "0 1px 6px 0 rgb(0 0 0 / 16%)",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
  ],
  typography: {
    fontSize: 12,
    fontFamily: "Heebo, sans-serif",
    h1: {
      fontSize: 60,
      lineHeight: pxToRem(67),
      fontWeight: 600,
    },
    h2: {
      fontSize: 34,
      lineHeight: pxToRem(35),
      fontWeight: 600,
    },
    h3: {
      fontSize: 20,
      lineHeight: pxToRem(24),
      fontWeight: 600,
    },
    h4: {
      fontSize: 15,
      lineHeight: pxToRem(18),
      fontWeight: 600,
    },
    h5: {
      fontSize: 13,
      lineHeight: pxToRem(14),
      fontWeight: 600,
    },
    h6: {
      fontSize: 10,
      lineHeight: pxToRem(12),
      fontWeight: 600,
    },
    button: {
      fontSize: 13,
      lineHeight: pxToRem(18),
      fontWeight: 600,
    },
    overline: {
      fontSize: 13,
      lineHeight: pxToRem(18),
    },
  },
  palette: {
    type: "light",
    background: {
      default: EVColors.white,
    },
    primary: {
      main: "#00A6FF",
      light: "#E5F6FF",
    },
    secondary: {
      main: "#000000",
    },
    error: {
      main: "#F45252",
    },
  },
});

theme.overrides = {
  MuiFormLabel: {
    root: {
      color: EVColors.formLabelColor,
    },
  },
  MuiInput: {
    root: {
      background: EVColors.white,
      marginBottom: theme.spacing(3),
    },
  },

  MuiMenu: {
    paper: {
      padding: 0,
      paddingTop: 0,
      paddingBottom: 0,
    },
    list: {
      padding: 0,
      paddingTop: 0,
      paddingBottom: 0,
    },
  },

  MuiCard: {
    root: {
      borderRadius: 12,
      padding: theme.spacing(6, 8),
    },
  },
  MuiCardContent: {
    root: {
      padding: 0,
      "&:last-child": {
        paddingBottom: 0,
      },
    },
  },
  MuiDrawer: {
    paper: {
      borderRadius: theme.spacing(3),
    },
  },
  MuiLink: {
    root: {
      color: theme.palette.primary.main,
      textDecoration: "none",
    },
    // "&:hover": {
    //   textDecoration: "none",
    // },
    underlineHover: {
      "&:hover": {
        textDecoration: "none !important",
      },
    },
  },
  MuiButton: {
    root: {
      padding: theme.spacing(2, 4),
      minHeight: 38,
      minWidth: 139,
      textTransform: "capitalize",
      fontWeight: 400,
    },
    containedPrimary: {
      backgroundColor: theme.palette.primary.main,
      color: EVColors.white,
      borderRadius: 4,
      boxShadow: "none",
      "&:hover": {
        color: EVColors.white,
        backgroundColor: theme.palette.primary.main,
        boxShadow: "none",
      },
      "&:active": {
        color: EVColors.white,
        backgroundColor: theme.palette.primary.main,
        boxShadow: "none",
      },
      "&:disabled": {
        color: EVColors.white,
        backgroundColor: theme.palette.primary.main,
        boxShadow: "none",
        opacity: ".4",
      },
    },
    containedSecondary: {
      backgroundColor: theme.palette.secondary.main,
      color: EVColors.white,
      borderRadius: 4,
      boxShadow: "none",
      "&:hover": {
        color: EVColors.white,
        backgroundColor: theme.palette.secondary.main,
        boxShadow: "none",
      },
      "&:active": {
        color: EVColors.white,
        backgroundColor: theme.palette.secondary.main,
        boxShadow: "none",
      },
      "&:disabled": {
        color: EVColors.white,
        backgroundColor: theme.palette.secondary.main,
        boxShadow: "none",
        opacity: ".4",
      },
    },
    contained: {
      backgroundColor: EVColors.greyLight,
      color: EVColors.greyDark,
      borderRadius: 4,
      boxShadow: "none",
      "&:hover": {
        color: EVColors.offBlack,
        backgroundColor: EVColors.grey,
        boxShadow: "none",
      },
    },
    text: {
      textDecoration: "none",
    },
    sizeSmall: {
      minWidth: 30,
      minHeight: 30,
      fontSize: 14,
      padding: theme.spacing(2, 6),
    },
    // textSeconday: {},
  },
  Mui: {
    error: {
      marginBottom: 8,
    },
  },
  MuiBox: {
    root: {
      padding: 0,
    },
  },
  MuiOutlinedInput: {
    input: {
      padding: theme.spacing(3, 3.5, 3),
    },
  },
  MuiInputLabel: {
    outlined: {
      transform: "translate(14px, 15px) scale(1)",
      "&.MuiInputLabel-shrink": {
        transform: "translate(14px, -4px) scale(0.75)",
      },
    },
  },
};

theme.palette.status = {
  blue: EVColors.statusBlue,
  lightBlue: EVColors.statusLightBlue,
  teal: EVColors.statusTeal,
  green: EVColors.statusGreen,
  purple: EVColors.statusPurple,
  yellow: EVColors.statusYellow,
  lightRed: EVColors.redLight,
};

theme.palette.color = {
  white: EVColors.white,
  offWhite: EVColors.offWhite,
  black: EVColors.black,
  offBlack: EVColors.offBlack,
  lightBlack: EVColors.lightBlack,
  textColor: EVColors.textColor,
  stepperGreyLite: EVColors.stepperGreyLite,

  //Grey
  greyDark: EVColors.greyDark,
  greyLight: EVColors.greyLight,
  greyFaint: EVColors.greyFaint,
  grey: EVColors.grey,

  bgBlueLight: EVColors.bgColor,
  darkBlue: EVColors.darkBlue,
  green: EVColors.green,
  orange: EVColors.orange,

  formLabelColor: EVColors.formLabelColor,
};

export default theme;
