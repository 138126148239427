import React from "react";
import { Header, Footer, Grid } from "@ccb/components/";
import { CssBaseline, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  mainContent: {
    paddingTop: 64,
  },
  container: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    minHeight: '100%',
  }
}));
//Basic layout will not contains sidebar
const BasicLayout = (props) => {
  const classes = useStyles();
  const { children } = props;
  return (
    <>
      <CssBaseline />
      <Container component="main" className={classes.container}>
        {props.header ? <props.header /> : null}
        <Grid className={classes.mainContent}>{children}</Grid>
        <Footer />
      </Container>
    </>
  );
};

BasicLayout.defaultProps={
  header: ()=>{
    <></>
  }
}

export default BasicLayout;
