/* eslint-disable react/no-unused-prop-types */
import React from "react";
import { CircularProgress } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { alpha } from '@material-ui/core/styles/colorManipulator';

const useStyles = makeStyles((theme) => ({
  page_loader: {
    width: "100%",
    height: "100%",
    position: "fixed",
    left: "0",
    top: "0",
    right: "0",
    bottom: "0",
    zIndex: 9999,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
    background: alpha("#000000", 0.1),
  },
  loader: {
	color: '#000000'
  },
}
))

function Loader() {
	const classes = useStyles();
  return (
    <div className={classes.page_loader}>
      <CircularProgress className={classes.loader} />
    </div>
  );
}

export default Loader;
