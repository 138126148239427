import { IconButton } from '@material-ui/core';

const IconButtons = (props) => {
    const {children} = props;
	return (
        <IconButton {...props}>
            {children}
        </IconButton>
    )
}

export default IconButtons;
