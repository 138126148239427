import { TextField } from "@material-ui/core";
import { useState } from "react";
import {
  IconButton,
  FormHelperText,
  FormControl,
  InputAdornment,
  makeStyles,
} from "@material-ui/core";
import { Visibility } from "@material-ui/icons";
import { VisibilityOff } from "@material-ui/icons";

import { Field } from "formik";

const useStyles = makeStyles((theme) => ({
  fullWidth: {
    width: "100%",
  },
  required: {
    color: theme.palette.error.main,
    display: "block",
    marginLeft: 2,
    marginRight: 14,
  },
}));

const RenderTextField = ({
  field, // { name, value, onChange, onBlur }
  ...props
}) => {
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <FormControl className={classes.fullWidth}>
      <TextField
        {...field}
        {...props}
        type={showPassword ? "text" : "password"}
        variant={props.variant || "outlined"}
        fullWidth
        disabled={props.isdisabled || false}
        value={props.form.values[field.name]}
        onChange={(event) => {
          props.form.setFieldValue(field.name, event.target.value);
          props.form.setFieldTouched(field.name, true);
        }}
        onBlur={(event) => {
          props.form.setFieldValue(field.name, event.target.value);
          props.form.setFieldTouched(field.name, true);
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                edge="end"
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <FormHelperText className={classes.required}>
        {props.form.touched[field.name] && props.form.errors[field.name]}
      </FormHelperText>
    </FormControl>
  );
};

const PasswordField = (props) => {
  return (
    <Field
      name={props.name}
      /* component={props.isMask ? RenderEmailField : RenderTextField} */
      component={RenderTextField}
      label={props.label}
      type="text"
      id={props.id}
      isdisabled={props.isdisabled}
      required={props.required}
      onKeyDown={(event) => {
        if (props.allowSpaces === false) {
          // If the input is a space and allowSpaces is false then prevent it
          if (event.keyCode === 32) {
            event.preventDefault();
          }
        }
      }}
    />
  );
};

PasswordField.defaultProps = {
  name: "",
  label: "",
  id: "",
  allowSpaces: true,
};

export default PasswordField;
