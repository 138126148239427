import {
    FormHelperText,
    FormControl,
    makeStyles,
} from "@material-ui/core";
import TxtField from "@material-ui/core/TextField";
import { Field } from "formik";

const useStyles = makeStyles((theme) => ({
    fullWidth: {
        width: "100%",
    },
    required: {
        color: theme.palette.error.main,
        display: "block",
        marginLeft: 2,
        marginRight: 14,
    },
}));

const RenderTextField = ({
    field, // { name, value, onChange, onBlur }
    ...props
}) => {
    const classes = useStyles();
    return (
        <FormControl className={classes.fullWidth}>
            <input
                // style={{width:"100%",height:"54px",padding:"0"}}
                className ={props.className}
                {...field}
                {...props}
                variant={props.variant || "outlined"}
                fullWidth
                type="color"
                disabled={props.isdisabled}
                value={props.form.values[field.name]}
                onChange={(event) => {
                    props.form.setFieldValue(field.name, event.target.value);
                    props.form.setFieldTouched(field.name, true);
                }}
                onBlur={(event) => {
                    props.form.setFieldValue(field.name, event.target.value);
                    props.form.setFieldTouched(field.name, true);
                }}
            />
            <FormHelperText className={classes.required}>
                {props.form.touched[field.name] && props.form.errors[field.name]}
            </FormHelperText>
        </FormControl>
    );
};

const ColorField = (props) => {
    return (
        <Field
            name={props.name}
            component={RenderTextField}
            label={props.label}
            type="color"
            id={props.id}
            isdisabled={props.isdisabled}
            required={props.required}
            className={props.className}
        />
    );
};

export default ColorField;
