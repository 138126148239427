import React from "react";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import CustomTabCard from "./CustomTabCard";
import { Button, Icon } from "@ccb/components/";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    "& .MuiTabs-indicator": {
      display: "none",
    },
  },
  tab: {
    background:"#FFFFFF"|| theme.palette.color.white,
    marginRight: theme.spacing(4),
    borderRadius: 12,
    padding: 0,
    "&.Mui-selected": {
      background: '#87CEEB'||theme.palette.color.darkBlue,
      color: "#FFFFFF"||theme.palette.color.white,
      "& h5": {
        color: "#FFFFFF"||theme.palette.color.white,
      },
      "& h2": {
        color:"#FFFFFF"|| theme.palette.color.white,
      },
      "& p": {
        color: '#87CEEB' ||theme.palette.color.orange,
        "& svg": {
          fill: '#87CEEB' ||theme.palette.color.orange,
        },
        "& span": {
          color: "#FFFFFF"||theme.palette.color.white,
        },
      },
    },
  },
  appbar: {
    "& .MuiTabs-root": {
      position: "relative",
    },
  },
  arrowLeft: {
    transform: "rotate(180deg)",
    margin: "-2px 2px 0 0",
  },
}));

const TabScrollButton = (props, ref) => {
  const { direction, ...other } = props;
  const classes = useStyles();
  const theme = useTheme();
  return (
    <Button
      // component="div"
      ref={ref}
      className={classes.btnAsd}
      // className={direction === "left" ? classes.btnleft : classes.btnright}
      style={
        direction === "left"
          ? {
              opacity: other.disabled ? 0 : 1,
              position: "absolute",
              left: 0,
              width: 37,
              height: 37,
              borderRadius: "50%",
              background: theme.palette.primary.light,
              minWidth: "inherit",
              zIndex: 9,
              top: `calc(50% - 20px)`,
              display: "flex",
              alignItems: "center",
            }
          : {
              opacity: other.disabled ? 0 : 1,
              position: "absolute",
              right: 0,
              width: 37,
              height: 37,
              borderRadius: "50%",
              background: theme.palette.primary.light,
              minWidth: "inherit",
              zIndex: 9,
              top: `calc(50% - 20px)`,
              display: "flex",
              alignItems: "center",
            }
      }
      {...other}
    >
      {direction === "left" ? <span className={classes.arrowLeft}><Icon name="keyboardArrowRight" /></span> : <Icon name="keyboardArrowRight" />}
    </Button>
  );
};

function CustomTabs(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const tabContent = props.tabData.map((elem, i) => (
    <div key={elem.name} className="tab-content-lists">
      <TabPanel value={value} index={i}>
        {elem.value}
      </TabPanel>
    </div>
  ));

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" color="transparent" className={classes.appbar}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="on"
          indicatorColor="primary"
          textColor="inherit"
          aria-label="scrollable force tabs example"
          ScrollButtonComponent={TabScrollButton}
        >
          {props.tabList &&
            props.tabList.map((tab, i) => (
              <Tab
                key={i}
                className={classes.tab}
                label={<CustomTabCard data={tab} />}
              />
            ))}
        </Tabs>
      </AppBar>
      {tabContent}
    </div>
  );
}

export default CustomTabs;
