import React from "react";
import clsx from "clsx";
import { makeStyles, createStyles } from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

const useStyles = makeStyles((theme) => 
    createStyles({

    })
)
const MenuDropdown = (props) => {
  const {
    items,
    onRenderButton,
    menuClassName,
    itemClassName,
    anchorOrigin = { vertical: "bottom", horizantal: "center" },
    transformOrigin = { vertical: "top", horizantal: "center" },
    closeOnClick = true,
    menuId,
  } = props;

  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const onToggle = (anchorEl) => {
    setAnchorEl(anchorEl);
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      {onRenderButton(onToggle, !!anchorEl)}
      <Menu
        elevation={2}
        className={classes.menu}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={(e) => {
          e.stopPropagation();
          onClose();
        }}
        getContentAnchorEl={null}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}   
        PaperProps={{
          id: menuId,
          className: clsx(classes.menuPaper, menuClassName),
        }}
      >
        {items?.map((item, index) => {
          return (
            <MenuItem
              key={index}
              className={clsx(classes.MenuItem, itemClassName)}
              onCLick={(e) => {
                if (closeOnClick) onClose();
                item.onClick?.(e);
              }}
            >
              {item.content}
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
};

MenuDropdown.defaultProps={
  onRenderButton: (event)=>{console.log("Function for onRenderButton")}
}

export default MenuDropdown;
