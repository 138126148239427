import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    breadcrumb: {
      fontSize: 11,
      color: theme.palette.color.black,
      "& p": {
        fontSize: 11,
      },
    },
    breadLink: {
      color: theme.palette.color.black,
      fontSize: 11,
    },
    bredcrumbTxt:{
      color:theme.palette.text.primary
    }
  })
);

export default function Breadcrumb(props) {
  const classes = useStyles();
  return (
    <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumb}>
      {props.list.map((i) => {
        if (i.route) {
          return (
            <Link
              underline="hover"
              key={`bc-${(i.text).replace(' ', '_')}`}
              color="inherit"
              title={i.text}
              href={i.route || "/"}
              className={classes.breadLink}
            >
              {i.text}
            </Link>
          );
        } else {
          return <Typography key={`bc-${(i.text).replace(' ', '_')}`} className={classes.bredcrumbTxt}>{i.text}</Typography>;
        }
      })}
    </Breadcrumbs>
  );
}
