const NoLayout = (props) => {
  const { children } = props;
  return (
    <>
      {children}
    </>
  );
};

export default NoLayout;
