import {
  Route,
  BrowserRouter as Router,
  Routes,
  Navigate,
} from "react-router-dom";
import { routes } from "./routes";
import AuthCheck from './AuthCheck';

// import LeftNav from "../pages/Nav/LeftNav";
import SideNav from "../components/SideNav/SideNav";
import Header from "../components/Header/Header";

const RouteWrapper = ({ component: Component, ...rest }) => {
  const renderSideNav = () => <SideNav navList={routes} />;

  const getBredcrumbs = (breadCrumbList, currentRouteIndex) => {
    const bList = [];
    breadCrumbList.includes(currentRouteIndex) ||
      breadCrumbList.push(currentRouteIndex);
    breadCrumbList.map((bIndex, i) => {
      routes.map((bdi) => {
        if (bIndex === bdi.index) {
          if (breadCrumbList.length === i + 1) {
            bList.push({ text: bdi.title });
          } else {
            bList.push({ text: bdi.title, route: bdi.path });
          }
        }
        return null;
      });
      return null;
    });
    return bList;
  };

  return (
    <Router>
      <Routes>
        {routes.map((route) => (
          <Route
            key={route.key}
            path={route.path}
            element={
              <route.layout
                {...route}
                header={
                  route.header === "Header"
                    ? () => (
                      <Header
                        {...route}
                        breadCrumbs={
                          route && route.breadcrumbs
                            ? getBredcrumbs(route.breadcrumbs, route.index)
                            : []
                        }
                      />
                    )
                    : false
                }
                sideNav={renderSideNav}
                container={route.container}
              >
                <AuthCheck path={route.path} auth={route.auth}>
                  <route.component />
                </AuthCheck>
              </route.layout>
            }
            exact
          />
        ))}
        <Route path="*" element={<Navigate replace to="/login" />} />
      </Routes>
    </Router>
  );
};

export default RouteWrapper;
