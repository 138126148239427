import {
  TextField,
  FormHelperText,
  FormControl,
  makeStyles,
} from "@material-ui/core";

import { Field } from "formik";

const useStyles = makeStyles((theme) => ({
  fullWidth: {
    width: "100%",
  },
  required: {
    color: theme.palette.error.main,
    display: "block",
    marginLeft: 2,
    marginRight: 14,
  },
}));

const RenderTextField = ({
  field, // { name, value, onChange, onBlur }
  ...props
}) => {
  const classes = useStyles();
  return (
    <FormControl className={classes.fullWidth}>
      <TextField
        {...field}
        {...props}
        name={field.name}
        variant={props.variant || "outlined"}
        fullWidth
        type="email"
        disabled={props.isdisabled}
        value={props.form.values[field.name]}
        required={props.required}
        onChange={(event) => {
          props.form.setFieldValue(field.name, event.target.value);
          props.form.setFieldTouched(field.name, true);
        }}
        onBlur={(event) => {
          props.form.setFieldValue(field.name, event.target.value);
          props.form.setFieldTouched(field.name, true);
        }}
      />
      <FormHelperText className={classes.required}>
        {props.form.touched[field.name] && props.form.errors[field.name]}
      </FormHelperText>
    </FormControl>
  );
};

const EmailField = (props) => {
  return (
    <Field
      name={props.name}
      /* component={props.isMask ? RenderEmailField : RenderTextField} */
      component={RenderTextField}
      label={props.label}
      id={props.id}
      isdisabled={props.isdisabled}
      required={props.required}
    />
  );
};

export default EmailField;
