import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
// import FilledInput from '@material-ui/core/FilledInput';
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import {
  ListSubheader,
  TextField,
  InputAdornment,
  FormHelperText,
  makeStyles,
} from "@material-ui/core";
import { Field } from "formik";
import SearchIcon from "@material-ui/icons/Search";

const useStyles = makeStyles((theme) => ({
  selectOutline: {
    width: "100%",
  },
  required: {
    color: theme.palette.error.main,
    display: "block",
    marginLeft: 2,
    marginRight: 14,
  },
  selectSearchWrap: {
    padding: theme.spacing(2, 4, 0, 4),
    background: theme.palette.color.white,
  },
  selectSearch: {
    width: "100%",
  },
  menuItemWrap: {
    whiteSpace: 'normal',
    wordWrap: 'break-word',
  },
}));

// Select component which will get rendered.
const RenderSelectField = ({
  field,
  onChange,
  form: { setFieldValue, values, setFieldTouched, touched, errors },
  ...props
}) => {
  const classes = useStyles();
  if (
    field &&
    !field.value &&
    props.isSingleDefault &&
    props.fieldvalues &&
    props.fieldvalues.length &&
    props.fieldvalues.length === 1
  ) {
    setFieldValue(field.name, props.fieldvalues[0].value);
  }

  const containsText = (text = "", searchText = "") => {
    if (Object.prototype.hasOwnProperty.call(text, "value")) {
      return (
        (text.label || "").toLowerCase().indexOf(searchText.toLowerCase()) > -1
      );
    } else {
      return text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
    }
  };

  const [searchText, setSearchText] = React.useState("");
  const displayedOptions = React.useMemo(
    () =>
      props.fieldvalues &&
      props.fieldvalues
        .filter((option) => containsText(option, searchText))
        .sort((a, b) => (a.label - b.label ? -1 : 1)),
    [searchText, props.fieldvalues]
  );

  useEffect(() => {
    setSearchText("");
  }, [props.fieldvalues]);

  return (
    <React.Fragment>
      <div className="form-control">
        <FormControl
          variant="outlined"
          fullWidth
          // className={`select-field ${field.value ? 'filled' : ''}`}
          disabled={props.isdisabled}
          className={classes.selectOutline}
        >
          <InputLabel>{props.label}</InputLabel>
          <Select
            {...field}
            {...props}
            disabled={props.isdisabled}
            onChange={(event) => {
              setFieldValue(event.target.name, event.target.value);
              if (onChange) {
                onChange(
                  event.target.name,
                  event.target.value,
                  setFieldValue,
                  values,
                  setFieldTouched,
                  touched
                );
              }
              setSearchText("");
            }}
            // input={
            //   <FilledInput label={props.label} name={field.name} fullWidth />
            // }
            MenuProps={{
              getContentAnchorEl: null,
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              PaperProps: {
                style: {
                  width: 100,
                  maxHeight: 300,
                },
              },
            }}
          >
            {props.fieldSearch && (
              <ListSubheader className={classes.selectSearchWrap}>
                <TextField
                  // Autofocus on textfield
                  autoFocus
                  placeholder="Type to search..."
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  className={classes.selectSearch}
                  onClick={(e) => e.stopPropagation()}
                  onChange={(e) => setSearchText(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key !== "Escape") {
                      // Prevents autoselecting item while typing (default Select behaviour)
                      e.stopPropagation();
                    }
                  }}
                />
              </ListSubheader>
            )}
            {props.defaultNone &&
            props.fieldvalues &&
            props.fieldvalues.length > 0 ? (
              <MenuItem
                value=""
                // style={{ minHeight: '20px', background: '#aba8a8' }}
                className={classes.menuItemWrap}
              >
                <i>Choose option</i>
              </MenuItem>
            ) : null}

            {searchText && props.fieldSearch
              ? displayedOptions &&
                displayedOptions.length > 0 &&
                displayedOptions
                  .filter((someValue) => !!someValue)
                  .map((option) =>
                    Object.prototype.hasOwnProperty.call(option, "value") ? (
                      <MenuItem key={option.value} value={option.value} className={classes.menuItemWrap}>
                        {option.label}
                      </MenuItem>
                    ) : (
                      <MenuItem key={option} value={option} className={classes.menuItemWrap}>
                        {option}
                      </MenuItem>
                    )
                  )
              : props.fieldvalues &&
                props.fieldvalues.length > 0 &&
                props.fieldvalues
                  .filter((someValue) => !!someValue)
                  .map((option) =>
                    Object.prototype.hasOwnProperty.call(option, "value") ? (
                      <MenuItem key={option.value} value={option.value} className={classes.menuItemWrap}>
                        {option.label}
                      </MenuItem>
                    ) : (
                      <MenuItem key={option} value={option} className={classes.menuItemWrap}>
                        {option}
                      </MenuItem>
                    )
                  )}
          </Select>
          <FormHelperText className={classes.required}>
            {touched[field.name] && errors[field.name]}
          </FormHelperText>
        </FormControl>
      </div>
    </React.Fragment>
  );
};

const SelectInput = (props) => {
  const selectField = (
    <Field
      name={props.name}
      label={props.label}
      id={props.id}
      component={RenderSelectField}
      fieldvalues={props.options}
      isSingleDefault={props.isSingleDefault}
      onChange={props.onChange}
      isdisabled={props.isdisabled}
      defaultNone={props.defaultNone}
      fieldSearch={props.fieldSearch}
    />
  );

  return <div>{selectField}</div>;
};

// Type Checking for the Props
SelectInput.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  isSingleDefault: PropTypes.bool,
  fieldSearch: PropTypes.bool,
};

// Specify the default Props values
SelectInput.defaultProps = {
  name: "",
  label: "",
  id: "",
  isSingleDefault: false,
};

export default SelectInput;
