import { ReactComponent as EvergentBlueLogo } from "./EvergentBlueLogo.svg";
import { ReactComponent as LightMode } from "./LightMode.svg";
import { ReactComponent as DarkMode } from "./DarkMode.svg";
import { ReactComponent as Close } from "./Close.svg";
import { ReactComponent as EvergentLogo } from "./EvergentLogo.svg";
import { ReactComponent as Home } from "./Home.svg";
import { ReactComponent as LibraryBooks } from "./LibraryBooks.svg";
import { ReactComponent as Tools } from "./Tools.svg";
import { ReactComponent as CalendarWithCircle } from "./CalendarWithCircle.svg";
import { ReactComponent as ArrowLeft } from "./ArrowLeft.svg";
import { ReactComponent as Search } from "./Search.svg";
import { ReactComponent as UserIconCircle } from "./UserIconCircle.svg";
import { ReactComponent as Notification } from "./Notification.svg";
import { ReactComponent as Plus } from "./Plus.svg";
import { ReactComponent as Recaptcha } from "./Recaptcha.svg";
import { ReactComponent as DragIndicator } from "./DragIndicator.svg";
import { ReactComponent as Download } from "./Download.svg";
import { ReactComponent as FileImage } from "./FileImage.svg";
import { ReactComponent as WebAsset } from "./WebAsset.svg";
import { ReactComponent as ArrowDown } from "./ArrowDown.svg";
import { ReactComponent as CheckCircle } from "./CheckCircle.svg";
import { ReactComponent as MoreOptions } from "./MoreOptions.svg";
import { ReactComponent as keyboardArrowRight } from "./keyboardArrowRight.svg";
import { ReactComponent as MoreVert } from "./MoreVert.svg";
import { ReactComponent as Delete } from "./Delete.svg";
import { ReactComponent as Delete2 } from "./Delete2.svg";
import { ReactComponent as Upload } from "./Upload.svg";
import { ReactComponent as ConfigAPIInfo } from "./ConfigAPIInfo.svg";
import { ReactComponent as ConfigHashTag } from "./ConfigHashTag.svg";
import { ReactComponent as ConfigManageAccount } from "./ConfigManageAccount.svg";
import { ReactComponent as ConfigPassword } from "./ConfigPassword.svg";
import { ReactComponent as ConfigPaymentGateway } from "./ConfigPaymentGateway.svg";
import { ReactComponent as ConfigServiceType } from "./ConfigServiceType.svg";
import { ReactComponent as CreditCardOff } from "./credit_card_off.svg";
import { ReactComponent as CreditScore } from "./credit_score.svg";
import { ReactComponent as FileDownload } from "./file_download.svg";
import { ReactComponent as DashboardGrid } from "./DashBoardGrid.svg";
import { ReactComponent as GroupIcon } from "./Group.svg";
import { ReactComponent as Hierarchy } from "./Hierarchy.svg";
import { ReactComponent as NewPartnerIcon } from "./NewPartnerIcon.svg";
import { ReactComponent as PartnerOrderRequests } from "./PartnerOrderRequests.svg";
import { ReactComponent as PartnersIcon } from "./Partners.svg";
import { ReactComponent as Reports } from "./Reports.svg";
import { ReactComponent as TransferArrows } from "./TransfersArrows.svg";
import { ReactComponent as MyWalletAdjustments } from "./WalletAdjustment-icon.svg";
import { ReactComponent as MyTransactionsSearch } from "./MyTransactionSearch.svg";
import { ReactComponent as UserPersona_icon } from "./UserPersonaIcon.svg";
import { ReactComponent as ExpandIcon_NE } from "./ExpandIcon_NE.svg";
import { ReactComponent as PrimaryContactIcon } from "./PrimaryContact.svg";
import { ReactComponent as EvergentLogoWhite } from "./EvergentLogoWhite.svg";
import { ReactComponent as Userprofile } from "./userprofile.svg";
import { ReactComponent as EditOutlined } from "./EditOutlined.svg";
import { ReactComponent as TableSearch } from "./TableSearch.svg";
import { ReactComponent as TableFilter } from "./TableFilter.svg";
import { ReactComponent as ViewTableCols } from "./ViewTableCols.svg";
import { ReactComponent as TableDownload } from "./TableDownload.svg";
import { ReactComponent as TablePrint } from "./TablePrint.svg";
import { ReactComponent as TreeView } from "./TreeView.svg";
import { ReactComponent as TableView } from "./TableView.svg";
import { ReactComponent as LeadIcon } from "./Lead_icon.svg";

export const iconTypes = {
  EvergentBlueLogo: EvergentBlueLogo,
  LightMode: LightMode,
  DarkMode: DarkMode,
  Close: Close,
  EvergentLogo: EvergentLogo,
  Home: Home,
  LibraryBooks: LibraryBooks,
  Tools: Tools,
  CalendarWithCircle: CalendarWithCircle,
  ArrowLeft: ArrowLeft,
  Search: Search,
  UserIconCircle: UserIconCircle,
  Notification: Notification,
  Plus: Plus,
  Recaptcha: Recaptcha,
  DragIndicator: DragIndicator,
  WebAsset: WebAsset,
  FileImage: FileImage,
  Download: Download,
  ArrowDown: ArrowDown,
  CheckCircle: CheckCircle,
  MoreOptions: MoreOptions,
  keyboardArrowRight: keyboardArrowRight,
  MoreVert: MoreVert,
  Delete: Delete,
  Upload: Upload,
  Delete2: Delete2,
  ConfigHashTag: ConfigHashTag,
  ConfigAPIInfo: ConfigAPIInfo,
  ConfigManageAccount: ConfigManageAccount,
  ConfigPassword: ConfigPassword,
  ConfigPaymentGateway: ConfigPaymentGateway,
  ConfigServiceType: ConfigServiceType,
  CreditCardOff: CreditCardOff,
  CreditScore: CreditScore,
  FileDownload: FileDownload,
  DashboardGrid: DashboardGrid,
  GroupIcon: GroupIcon,
  Hierarchy: Hierarchy,
  NewPartnerIcon: NewPartnerIcon,
  PartnerOrderRequests: PartnerOrderRequests,
  PartnersIcon: PartnersIcon,
  Reports: Reports,
  ExpandIcon_NE: ExpandIcon_NE,
  PrimaryContactIcon: PrimaryContactIcon,
  TransferArrows : TransferArrows,
  MyWalletAdjustments : MyWalletAdjustments,
  MyTransactionsSearch : MyTransactionsSearch,
  UserPersona_icon : UserPersona_icon,
  EvergentLogoWhite: EvergentLogoWhite,
  Userprofile: Userprofile,
  EditOutlined: EditOutlined,
  TableSearch: TableSearch,
  TableFilter: TableFilter,
  ViewTableCols: ViewTableCols,
  TableDownload: TableDownload,
  TablePrint: TablePrint,
  TreeView: TreeView,
  TableView: TableView,
  LeadIcon:LeadIcon
};

const IconComponent = (props) => {
  const { name, ...rest } = props;
  const Icon = iconTypes[name];

  if (!Icon) {
    return <></>;
  }

  return <Icon {...rest} />;
};

export default IconComponent;
