import {
  TextField,
  FormHelperText,
  FormControl,
  makeStyles,
} from "@material-ui/core";
import { Field } from "formik";

const useStyles = makeStyles((theme) => ({
  fullWidth: {
    width: "100%",
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none"
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none"
    },
    "& input[type=number]": {
      "-moz-appearance": "textfield"
    }
  },
  required: {
    color: theme.palette.error.main,
    display: "block",
    marginLeft: 2,
    marginRight: 14,
  },

}));

const RenderNumberField = ({
  field, // { name, value, onChange, onBlur }
  ...props
}) => {
  const classes = useStyles();
  return (
    <FormControl className={classes.fullWidth}>
      <TextField
        {...field}
        {...props}
        variant={props.variant || "outlined"}
        fullWidth
        type="text"
        disabled={props.isdisabled}
        value={props.form.values[field.name]}
        required={props.required}
        onChange={(event) => {
          if(!isNaN(event.target.value) || !event.target.value.length ){
            if (props.maxLength) {
              if (!event.target.value || event.target.value.toString().length <= props.maxLength) {
                props.form.setFieldValue(field.name, event.target.value);
                props.form.setFieldTouched(field.name, true);
              }
            }
            else {
              props.form.setFieldValue(field.name, event.target.value);
              props.form.setFieldTouched(field.name, true);
            }
          }
        }}
        onBlur={(event) => {
          props.form.setFieldValue(field.name, event.target.value);
          props.form.setFieldTouched(field.name, true);
        }}
      />
      <FormHelperText className={classes.required}>
        {props.form.touched[field.name] && props.form.errors[field.name]}
      </FormHelperText>
    </FormControl>
  );
};

const NumberField = (props) => {
  return (
    <Field
      name={props.name}
      /* component={props.isMask ? RenderEmailField : RenderNumberField} */
      component={RenderNumberField}
      label={props.label}
      type="text"
      id={props.id}
      isdisabled={props.isdisabled}
      required={props.required}
      onKeyDown={(event) => {
        if (props.allowDecimals === false) {
          // If the input is a decimal and allowDecimals is false then prevent it
          if (event.keyCode === 190) {
            event.preventDefault();
          }
        }
      }}
      {...props}
    />
  );
};

NumberField.defaultProps = {
  name: "",
  label: "",
  id: "",
  allowDecimals: true,
};
export default NumberField;
