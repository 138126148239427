import React from "react";
import Card from "@material-ui/core/Card";
import { makeStyles } from "@material-ui/core";
import { EVTypography, Icon } from "@ccb/components";

const useStyles = makeStyles((theme) => ({
  card: {
    background: '#87CEEB' ||theme.palette.color.lightBlack,
    borderRadius: 12,
    padding: theme.spacing(3, 4),
    cursor: "pointer",
    "& img": {
        borderRadius: 8,
    }
  },
}));

const ConfigCard = (props) => {
  const { icon, title, description, onCardClick } = props;
  const classes = useStyles();
  return (
    <Card elevation={0} className={classes.card} onClick={onCardClick}>
      <img src={icon} alt="icons" />
      <EVTypography variant="body2" gutterBottom>
        {title}
      </EVTypography>
      <EVTypography variant="caption" display="block" gutterBottom>
        {description}
      </EVTypography>
    </Card>
  );
};

export default ConfigCard;
