import { Toolbar as EvToolbar } from '@material-ui/core';

const Toolbar = (props) => {
    const { children } = props;
    return (
        <EvToolbar {...props}>
            {children}
        </EvToolbar>
    )
}

export default  Toolbar;