import { Field } from "formik";
import {
    TextField,
    FormHelperText,
    FormControl,
    makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    fullWidth: {
        width: "100%",
        "& input[type=number]::-webkit-inner-spin-button": {
            "-webkit-appearance": "none"
        },
        "& input[type=number]::-webkit-outer-spin-button": {
            "-webkit-appearance": "none"
        },
        "& input[type=number]": {
            "-moz-appearance": "textfield"
        }
    },
    required: {
        color: theme.palette.error.main,
        display: "block",
        marginLeft: 8,
        marginRight: 14,
        fontSize: "12px !important",
    },
    input: {
        display: "inline-block",
        width: 50,
        height: 50,
        textAlign: "center",
        borderBottom: "1px solid",
        borderTop: "none",
        borderLeft: "none",
        borderRight: "none",
        margin: theme.spacing(0, 1),
    }
}));


const OTP = ({ field, ...props }) => {
    const classes = useStyles();

    let style = {
        display: "inline-block",
        width: "50px",
        height: "50px",
        textAlign: "center",
        borderBottom: "1px solid",
        borderTop: "none",
        borderLeft: "none",
        borderRight: "none",
        margin: "0 4px",
        ":focus-visible": {
            border: "none",
            outline: "none"
        }
    };

    return (
        <FormControl className={classes.fullWidth}>
            <div class="row justify-content-md-center">
                {[...Array(props.otpLength)].map((i, j) => {
                    return (
                        <input className={classes.input} id={'otp-' + (j + 1)} pattern="\d*" maxlength="1" type={'text'}
                            onChange={(event) => {
                                if (!event.target.value || !isNaN(event.target.value)) {
                                    let otp = new Array(props.otpLength);
                                    [...Array(props.otpLength)].map((k, l) => {
                                        let field = document.getElementById('otp-' + (l + 1));
                                        otp[l] = field.value;
                                    })
                                    // {console.log(props.form.touched[field.name],props.form.errors[field.name],props.form.touched[field.name] && props.form.errors[field.name])}
                                    console.log('props.form', props.form.values)
                                    props.form.setFieldValue(field.name, otp.toString().replaceAll(',', ''));
                                    if (event.target.value) {
                                        let field = document.getElementById('otp-' + (j + 2));
                                        if (field)
                                            field.focus();
                                    }
                                    else {
                                        let field = document.getElementById('otp-' + (j));
                                        if (field)
                                            field.focus();
                                    }
                                }
                            }}

                        />)
                })}
            </div>
            <FormHelperText className={classes.required}>
                {props.form.errors[field.name]}
            </FormHelperText>
        </FormControl>
    )
}

const OTPField = (props) => {
    return (
        <Field
            name={props.name}
            component={OTP}
            label={props.label}
            type="text"
            id={props.id}
            isdisabled={props.isdisabled}
            required={props.required}
            otpLength={props.otpLength}
        />
    );
};



export default OTPField;