import React from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Field } from "formik";

const useStyles = makeStyles((theme) => ({
  checkbox: {
    padding: 0,
    color: theme.palette.primary.main,
    margin: 0,
  },
  checkboxWithLabel: {
    color: "#c4c4c4",
  },
  required: {
    color: theme.palette.error.main,
    display: "block",
    marginLeft: 14,
    marginRight: 14,
  },
  checklabel: {
    margin: 0,
    display: "flex",
  }
}));

const RenderCheckboxFieldWithoutLabel = ({
  field, // { name, value, onChange, onBlur }
  ...props
}) => {
  const classes = useStyles();
  let options = {
    inputProps: { "aria-label": "Checkbox demo" },
    ...(props.label ? { label: props.label } : null),
    ...(props.icon ? { icon: props.icon } : null),
    ...(props.color ? { color: props.color } : null),
  };
  return (
    <Checkbox
      className={classes.checkbox}
      color="primary"
      checked={!!props.form.values[field.name]}
      onClick={(e) => {
        props.form.setFieldValue(field.name, !props.form.values[field.name]);
      }}
      required={props.required}
      {...props}
      disabled={props.isdisabled}
      {...options}
    />
  );
};

const RenderCheckbox = ({ field, ...props }) => {
  const classes = useStyles();
  return (
    <FormControl component="fieldset" className={classes.formControl}>
      <p className={classes.checklabel}>
        <FormControlLabel
          value={props.labelPlacement}
          control={
            <Checkbox
              color="primary"
              onChange={(e) => {
                props.form.setFieldValue(field.name, e.target.checked);
                props.form.setFieldTouched(field.name, true);
              }}
              onBlur={(e) => {
                props.form.setFieldValue(field.name, e.target.checked);
                props.form.setFieldTouched(field.name, true);
              }}
              checked={!!props.form.values[field.name]}
              required={props.required}
            />
          }
          label={props.label}
          labelPlacement={props.labelPlacement}
          {...props}
        />
        <img src={props.rightimg} alt={props.altimg} />
      </p>
      {props.form.touched[field.name] && props.form.errors[field.name] && (
        <FormHelperText className={classes.required}>
          {props.form.touched[field.name] && props.form.errors[field.name]}
        </FormHelperText>
      )}
    </FormControl>
  );
};

const CheckboxField = (props) => {
  return (
    <Field
      name={props.name}
      component={
        props.labelPlacement ? RenderCheckbox : RenderCheckboxFieldWithoutLabel
      }
      label={props.label}
      type="text"
      id={props.id}
      isdisabled={props.isdisabled}
      required={props.required}
      rightimg={props.rightimg}
      {...props}
    />
  );
};

export default CheckboxField;
