/* eslint-disable react/jsx-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useMemo, useEffect, useState } from 'react';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Grid, Button, Dialog, Link, Tooltip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import GetAppIcon from '@material-ui/icons/GetApp';
import { each, findIndex } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';
import { useDropzone } from 'react-dropzone';

import { Document, Page, pdfjs } from 'react-pdf';
import Carousel from 'react-material-ui-carousel';
import DefaultImgIcon from '../Image/default-img.png';
import DeleteIcon from '../Image/delete-icon.png';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import IconButton from '@material-ui/core/IconButton';
import PDFIcon from '../Image/pdfIcon.png';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const styles = (theme) => ({
  baseStyle: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    color: theme.primary,
    outline: 'none',
    transition: 'border .24s ease-in-out',
    cursor: 'pointer',
  },
  ptag: {
    lineHeight: '20px',
    margin: '0',
    textAlign: 'center',
    color: theme.primary,
  },
  smallText: {
    lineHeight: '20px',
    margin: '0',
    textAlign: 'center',
    fontSize: '9pt',
    opacity: '0.75',
    paddingTop: '22px',
    color: theme.primary,
  },
});

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  color: '#000000',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  cursor: 'pointer',
  // maxWidth: '420px',
  // minWidth: '420px'
};

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16,
};

const thumb = {
  display: 'inline-flex',
  marginBottom: 8,
  marginRight: 20,
  width: 150,
  padding: 0,
  boxSizing: 'border-box'
};

const thumbPdf = {
  display: 'flex',
  height: 80,
  marginBottom: 8,
  marginRight: 20,
  padding: 0,
  boxSizing: 'border-box',
};

const thumbInner = {
  display: 'flex',
  alignItems: 'center',
  // marginRight: '10px',
};

const rightWrap = {

};

const img = {
  display: 'block',
  width: '100%',
  height: 'auto',
  objectFit: 'cover',
  cursor: 'pointer',
};

const imgFull = {
  display: 'block',
  width: '100%',
  height: 'auto',
  objectFit: 'cover',
};

const pdfFull = {
  display: 'block',
  width: '100%',
  height: 'auto',
  objectFit: 'cover',
  minHeight: '500px',
};

const customStyles = {
  imageDiv: {
    textAlign: 'center',
  },
  textDiv: {
    paddingLeft: '15px',
    wordBreak: 'break-all',
  },
  deleteDiv: {
    textAlign: 'center',
    height: '40px',
    width: '68px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    margin: '4px auto',
  },
  delete: {
    background: '#ffffff',
    padding: '8px',
  },
  addFile: {
    padding: '8px',
  },
};
const activeStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};
const errorText = {
  position: 'inherit',
};
const uploadButtonStyle = {
  marginBottom: '5px',
};
const test = {
  height: '300px',
};

const useStyles = makeStyles((theme) => ({
  uploadedCard: {
    padding: '10px',
    border: '1px dashed #d2d2d2',
    marginRight: '5px',
    width: 170,
    height: 115,
  },
  rightWrap: {
    width: 30,
  },
  thumbInner: {
    width: 80,
    textAlign: 'center',
    alignItems: 'center',
    display: 'flex',
    paddingRight: 10,
    '& button': {
      padding: 0,
    }
  },
  image: {
    width: '80%',
  },
  pdfIcon: {
    padding: 0,
  },
  imageWrap: {
    // width: 40,
  },
  fileName: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontSize: 11,
  },
  UploadMoreBtn: {
    border: '1px solid #00A6FF',
    borderRadius: 6,
    background: '#E6F6FF',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: 130,
    height: 115,
    padding: 20,
    textAlign: 'center',
    cursor: 'pointer',
    '& span': {
      fontSize: 13,
    },
    '& p': {
      fontSize: 14,
      fontWeight: 500,
      margin: 0,
      marginTop: 10,
      textDecoration: 'underline',
    }
  },
  popWrap: {
    textAlign: 'center',
    width: '100%',
    padding: 20,
    '& img': {
      display: 'initial !important'
    }  
  },
  pdfPopWrap: {
    textAlign: 'center',
    width: '100%',
    padding: 20,
    height : '500px',
    overflow: 'scroll',
    '& img': {
      display: 'initial !important'
    }
  }
}));

const RenderFileUploadInput = ({
  field,
  form: { setFieldValue },
  ...props
}) => {
  const customCss = useStyles();
  const [filesToShow, setFilesToShow] = useState([]);
  const [isThumbnailShowing, setIsThumbnailShowing] = useState(false);
  const [uploadError, setUploadError] = useState(false);
  const [open, setOpen] = useState(false);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [previewOrderStartFrom, setPreviewOrderStartFrom] = useState();
  const [previewOrder, setPreviewOrder] = useState([]);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumberOfPages(numPages);
  };

  const convertToBase64 = (file) =>
    new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });

  const encodeFiles = (selectedFiles) => {
    const tempFileInfo = cloneDeep(filesToShow);
    setUploadError(false);
    setIsThumbnailShowing(true);
    const tempFilesToShow = [];
    const tempFieldValues = [];
    const allFilesData = [...selectedFiles, ...tempFileInfo];
    Promise.all(
      allFilesData.map(async (eachSelectedFile) => {
        if (eachSelectedFile.fileData.includes('blob:')) {
          const base64Data = await convertToBase64(eachSelectedFile);
          tempFilesToShow.push({
            fileData: base64Data,
            fileName: eachSelectedFile.name,
          });
          tempFieldValues.push({
            fileData: base64Data,
            fileName: eachSelectedFile.name,
          });
        }
      }),
    ).then(() => {
      if (field.value) {
        const tempAttachData = [...field.value, ...tempFieldValues];
        const keys = ['fileName'];
        const filtered = tempAttachData.filter(
          ((s) => (o) =>
            ((k) => !s.has(k) && s.add(k))(keys.map((k) => o[k]).join('|')))(
            new Set(),
          ),
        );
        setFilesToShow(filtered);
        setFieldValue(field.name, filtered);
        // fieldonchange added for add attachement fn for astro po
        const tempSelectedFiles = cloneDeep(selectedFiles);
        const filteredSelectedFiles = [];
        if (!isEmpty(tempSelectedFiles)) {
          each(tempSelectedFiles, (attach) => {
            const getIdx = findIndex(tempFilesToShow, {
              fileName: attach.fileName,
            });
            if (getIdx !== -1) {
              filteredSelectedFiles.push(tempFilesToShow[getIdx]);
            }
          });
        }
        props.fieldOnChange(props.id, filteredSelectedFiles);
      } else {
        setFilesToShow([...tempFilesToShow]);
        setFieldValue(field.name, [...tempFieldValues]);
      }
    });
  };

  const dropzoneOptions = {};
  dropzoneOptions.multiple = true;
  dropzoneOptions.onDropAccepted = encodeFiles;
  // If we want to allow all file types we should pass * in props. If we remove accept key in dropzoneOptions, then it will allow all types
  if (props.accept !== '*') {
    dropzoneOptions.accept = props.accept || '.png,.jpg,.jpeg,.pdf,.bmp';
  }
  dropzoneOptions.onDrop = (acceptedFiles) => {
    setFilesToShow(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          fileName: file.name,
          fileData: URL.createObjectURL(file),
        }),
      ),
    );
  };

  useEffect(() => {
    if (props.filesObjectsArray?.length) {
      if (filesToShow.length === 0) {
        setFilesToShow([...props.filesObjectsArray]);
        setFieldValue(field.name, [...field.value]);
        setIsThumbnailShowing(true);
      }
    }
  }, []);

  useEffect(() => {
    if (isEmpty(props.filesObjectsArray)) {
      setFilesToShow([]);
      setIsThumbnailShowing(false);
    }
  }, [props.filesObjectsArray]);

  useEffect(() => {
    /* this is fn handle to show the selected thumb image preview */
    if (previewOrderStartFrom !== 0) {
      const carouselImagesData = cloneDeep(filesToShow);
      const afterSelectdIdxData =
        carouselImagesData &&
        carouselImagesData.splice(
          previewOrderStartFrom,
          carouselImagesData.length,
        );
      const beforeSelectIdxData =
        carouselImagesData &&
        carouselImagesData.splice(0, previewOrderStartFrom);
      setPreviewOrder([...afterSelectdIdxData, ...beforeSelectIdxData]);
    }
  }, [previewOrderStartFrom]);

  if (props && props.fileExtenstion) {
    dropzoneOptions.accept = props.fileExtenstion;
  }

  const deleteThatFile = (allFiles, deleteIndex) => {
    const copiedFiles = cloneDeep(allFiles);
    copiedFiles.splice(deleteIndex, 1);
    if (props.fieldOnDeleteHandler) {
      props.fieldOnDeleteHandler(field.name, deleteIndex);
    }
    setFilesToShow(copiedFiles);
    setFieldValue(field.name, copiedFiles);
    if (isEmpty(copiedFiles)) {
      setIsThumbnailShowing(false);
    }
  };

  const thumbNailsArray = filesToShow.map((eachFile, fileIndex) => (
    <div
      className={customCss.uploadedCard}
      onClick={() => {
        setOpen(true);
        if (fileIndex == 0) {
          setPreviewOrder(filesToShow);
        } else {
          setPreviewOrderStartFrom(fileIndex);
        }
      }}
    >
      <div
        style={eachFile.fileName.indexOf('pdf') >= 0 ? thumbPdf : thumb}
        key={eachFile.fileName}
      >
        <div className={customCss.thumbInner}>
          {eachFile.fileName.indexOf('pdf') >= 0 ? (
            <img
              src={PDFIcon}
              className={customCss.image}
              alt="404 image"
            />
          ) : eachFile.fileName.indexOf('jpg') >= 0 ||
            eachFile.fileName.indexOf('png') >= 0 ||
            eachFile.fileName.indexOf('bmp') >= 0 ||
            eachFile.fileName.indexOf('jpeg') >= 0 ? (
              <img
                src={eachFile.fileData}
                className={customCss.image}
                alt={eachFile.fileName}
                onClick={() => setOpen(true)}
              />
          ) : (
            <IconButton aria-label="file" className={customCss.pdfIcon}>
              <InsertDriveFileIcon
                fontSize="large"
                style={{ fontSize: '70px' }}
              />
            </IconButton>
          )}
        </div>
        <div className={customCss.rightWrap}>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              deleteThatFile(filesToShow, fileIndex);
            }}
            style={customStyles.deleteDiv}
            variant="outlined"
            color="secondary"
            size="small"
            className={'actionBtn outlinedPrimary mb-10'}
          >
            <svg
              className="svg_icon primary"
              id="Layer_1"
              data-name="Layer 1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 162.54 114.08"
            >
              <defs></defs>
              <title>Delete</title>
              <rect
                className="cls-1"
                x="55.67"
                y="39.64"
                width="58.39"
                height="64.49"
              />
              <rect
                className="cls-1"
                x="45.65"
                y="23.71"
                width="76.15"
                height="15.93"
                rx="2.47"
              />
              <rect
                className="cls-1"
                x="78.32"
                y="10.01"
                width="11.36"
                height="11.36"
                rx="2.43"
              />
              <line
                className="cls-1"
                x1="69.55"
                y1="52.37"
                x2="69.55"
                y2="88.92"
              />
              <line
                className="cls-1"
                x1="82.84"
                y1="52.37"
                x2="82.84"
                y2="88.92"
              />
              <line
                className="cls-1"
                x1="96.66"
                y1="52.37"
                x2="96.66"
                y2="88.92"
              />
            </svg>
          </Button>
          <Button
            style={customStyles.deleteDiv}
            variant="outlined"
            color="secondary"
            size="small"
            className={'actionBtn outlinedPrimary'}
            download={eachFile.fileName}
            href={eachFile.fileData}
          >
            <GetAppIcon />
          </Button>
        </div>
      </div>
      <Tooltip
        title={eachFile.fileName}
        placement="bottom-start"
      >
        <div className={customCss.fileName}>{eachFile.fileName}</div>
      </Tooltip>
    </div>
  ));

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone(dropzoneOptions);

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject],
  );
  const { classes } = props;
  return (
    <React.Fragment>
      {!isThumbnailShowing ? (
        <div className={props.className || ''}>
          {props.simpleUI === 'yes' ? (
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <Button
                variant="contained"
                style={uploadButtonStyle}
                color="secondary"
                type="submit"
                className="btn large"
              >
                {props.label}
              </Button>
            </div>
          ) : (
            <div {...getRootProps({ style })} className={props.disable ? 'disableUpload' : ''}>
              <input {...getInputProps()} />
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
              >
                <Grid item xs={8}>
                  <p className={classes.ptag}>
                    {props.label
                      ? props.label
                      : 'Drag your logo here or Click to upload it.'}
                  </p>
                  <p className={classes.smallText}>
                    {props.dimenstionsLabel
                      ? props.dimenstionsLabel
                      : 'Best dimensions 400 x 400 (w x h)'}
                  </p>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={3} style={customStyles.imageDiv}>
                  <img src={DefaultImgIcon} alt="default icon" width="64" />
                </Grid>
              </Grid>
            </div>
          )}
        </div>
      ) : (
        <div className={props.className || ''}>
          <div style={thumbsContainer}>
            {thumbNailsArray}
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <div className={customCss.UploadMoreBtn}>
                <span>Upload your files here</span>
                <p>Browse</p>
              </div>
            </div>
          </div>
        </div>
      )}

      {uploadError ? (
        <span className="required" style={errorText}>
          Unable to upload file. Please try again.
        </span>
      ) : (
        ''
      )}
      {open && (
        <Dialog
          onClose={() => {
            setOpen(false);
            setPreviewOrder([]);
            setPreviewOrderStartFrom(0);
          }}
          aria-labelledby="simple-dialog-title"
          open={open}
          fullWidth
        >
          <Carousel autoPlay={false}>
            {previewOrder.map((item, i) =>
              (item.fileName.indexOf('pdf') >= 0 ? (
                <div className={customCss.pdfPopWrap}>
                  <div style={customStyles.deleteDiv}>
                    <Link download={item.fileName} href={item.fileData}>
                      <GetAppIcon />
                    </Link>
                  </div>
                  <Document
                    file={item.fileData}
                    onLoadSuccess={onDocumentLoadSuccess}
                    onContextMenu={(e) => e.preventDefault()}
                  >
                    {[...Array(numberOfPages).keys()].map(
                      (eachPage, eachPageIndex) => (
                        <Page
                          key={`page_${eachPageIndex + 1}`}
                          pageNumber={eachPageIndex + 1}
                        />
                      ),
                    )}
                  </Document>
                </div>
              ) : item.fileName.indexOf('jpg') >= 0 ||
                item.fileName.indexOf('png') >= 0 ||
                item.fileName.indexOf('bmp') >= 0 ||
                item.fileName.indexOf('jpeg') >= 0 ? (
                  <div className={customCss.popWrap}>
                    <img src={item.fileData} style={imgFull} alt="" />
                  </div>
              ) : (
                <div className={customCss.popWrap}>
                  <div name="download" style={customStyles.deleteDiv}>
                    <Link download={item.fileName} href={item.fileData}>
                      <GetAppIcon />
                    </Link>
                  </div>
                  <IconButton aria-label="file">
                    <InsertDriveFileIcon fontSize="large" style={imgFull} />
                  </IconButton>
                </div>
              )),
            )}
          </Carousel>
        </Dialog>
      )}
    </React.Fragment>
  );
};

const MultiFileUpload = (props) => (
  <React.Fragment>
    <Field
      name={props.fieldName}
      showDeleteIcon={props.showDeleteIcon}
      component={RenderFileUploadInput}
      filesObjectsArray={props.filesObjectsArray}
      label={props.fieldLabel}
      dimenstionsLabel={props.fieldDimenstionsLabel}
      id={props.fieldID}
      fileType={props.fieldFileType}
      simpleUI={props.fieldSimpleUI}
      accept={props.accept}
      fileExtenstion={props.fieldFileExtention}
      classes={props.classes}
      className={props.className}
      fieldOnChange={props.fieldOnChange}
      fieldOnDeleteHandler={props.fieldOnDeleteHandler}
      disable={props.disable}
    />
  </React.Fragment>
);

MultiFileUpload.propTypes = {
  fieldName: PropTypes.string,
  fieldID: PropTypes.string,
  fieldFileType: PropTypes.string.isRequired,
  filesObjectsArray: PropTypes.array.isRequired,
  fieldSimpleUI: PropTypes.string.isRequired,
  fieldFileExtention: PropTypes.string,
  fieldLabel: PropTypes.string,
  fieldDimenstionsLabel: PropTypes.string,
  accept: PropTypes.string,
  className: PropTypes.string,
  fieldOnChange: PropTypes.func,
  fieldOnDeleteHandler: PropTypes.func,
};

MultiFileUpload.defaultProps = {
  fieldName: '',
  fieldID: '',
  fieldLabel: ' ',
  fieldDimenstionsLabel: '',
  accept: '.png,.jpg,.jpeg,.pdf,.bmp',
};

export default withStyles(styles)(MultiFileUpload);
