import React from "react";
import { Image } from "@ccb/components/";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  card: {
    minHeight: 72,
    width: "100%",
    display:"grid",
    alignItems:"center",
    justifyContent:"center",
    cursor:"pointer",
    border:"1px solid",
    borderRadius:theme.spacing(2),
  },
  active:{
    border:"2px solid",
    borderColor:theme.palette.primary.main,
    borderRadius:theme.spacing(2),
    minHeight: 72,
    width: "100%",
    display:"grid",
    alignItems:"center",
    justifyContent:"center",
    cursor:"pointer"
  }
}));

const PaymentCard = (props) => {
  const classes = useStyles();
  const { data, click, isActive } = props;

  return (
    <>
      <div
        className={clsx(classes.card, isActive && classes.active)}
        onClick={(e) => {
          e.preventDefault();
          if (click) {
            click(data.cardType)
          }
        }}
      >
        <Image alt={data.cardType} src={data.img} />

      </div>
    </>
  );
};

export default PaymentCard;
