import React, { useState, useEffect } from "react";
import { Dialog, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { AppTheme, EVTypography, Icon } from "@ccb/components/";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiPaper-root": {
      borderRadius: 12,
    },
  },
  modalWrapper: {
    padding: theme.spacing(5),
  },
  closeButton: {
    minWidth: 30,
    borderRadius: 10,
    height: 32,
    width: 32,
    padding: 0,
    marginLeft: theme.spacing(4),
    // background: theme.palette.color.greyLight,
    // "&:hover": {
    //   background: theme.palette.color.greyDark,
    // },
  },
  titleRow: {
    display: "flex",
    justifyContent: "space-between",
  },
  close: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));

const Modal = (props) => {
  const classes = useStyles();
  const {
    children,
    isOpen,
    toggleOpenFn,
    disableBackdropClick,
    heading,
    headingAlign,
    className,
    modalWrapperClass
  } = props;
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <Dialog
      className={clsx(classes.root, className)}
      open={open}
      maxWidth={props.maxWidth}
      disableBackdropClick={disableBackdropClick}
      onClose={toggleOpenFn}
      closeAfterTransition
      BackdropProps={{ timeout: 500 }}
    >
      <div className={clsx(classes.modalWrapper, modalWrapperClass, "modalWrapper")}>
        <div className={heading ? classes.titleRow : classes.close}>
          {!!heading && (
            <EVTypography variant="h3" align={headingAlign || 'left'}>
              {heading}
            </EVTypography>
          )}
          {!!toggleOpenFn && (
            <IconButton
              className={classes.closeButton}
              onClick={() => toggleOpenFn()}
            >
              <Icon name="Close" />
            </IconButton>
          )}
        </div>
        <div className={classes.content}>{children}</div>
      </div>
    </Dialog>
  );
};

export default Modal;
