import React from "react";
import { Paper, Popper, Card } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  paperWrap: {
    borderRadius: 14,
    padding: theme.spacing(5),
  },
}));

const Popover = (props) => {
  const {
    children,
    elevation,
    customClass,
    anchorEl,
    popOverId,
    anchorOrigin,
    transformOrigin,
    customPoperBorderRadius,
    customeCardClass
  } = props;
  const classes = useStyles();
  const open = Boolean(anchorEl);
  const id = open ? popOverId : undefined;

  const [popoverAnchorOrigin, setPopoverAnchorOrigin] = React.useState(
    anchorOrigin ?? {
      vertical: "top",
      horizontal: "right",
    }
  );

  const [popoverTransformOrigin, setPopoverTransformOrigin] = React.useState(
    transformOrigin ?? {
      vertical: "bottom",
      horizontal: "right",
    }
  );

  return (
    <Popper
      id={id}
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={popoverAnchorOrigin}
      transformOrigin={popoverTransformOrigin}
      PaperProps={{
        borderRadius: customPoperBorderRadius
          ? customPoperBorderRadius
          : "none",
      }}
    >
      <Card
        id={"popover-card"}
        className={clsx(classes.card, customeCardClass)}
      >{children}</Card>
    </Popper>
  );
};

export default Popover;
