import React from "react";
import { Link, createStyles, makeStyles } from "@material-ui/core";

const pxToRem = (value) => {
  return `${value / 16}rem`;
}

const useStyles = makeStyles((theme) => createStyles({
    link: {
        fontSize: pxToRem(12),
        margin: theme.spacing(0, 4),
    }
}));

const EVLink = (props) => {
  const classes = useStyles();
  const { children } = props;
  return <Link className={classes.link} {...props}>{children}</Link>;
};

export default EVLink;
