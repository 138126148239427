import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => (
  {
    snackbar: {
        color: theme.palette.primary.main,
        background: theme.palette.background.paper
    }
  }
))



const CustomSnackbar = ({ vertical, horizontal, message, autoHideDuration, onClose, allowClose = false, open, ...props }) => {
  const classes = useStyles();

  return (
    <div>
      <Snackbar
        ContentProps={{
          classes: {
            root: classes.snackbar
          }
        }}
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        message={message}
        autoHideDuration={autoHideDuration || 6000}
        onClose={(e) => {
          if (onClose) {
            onClose(e);
          }
        }}
        key={vertical + horizontal}
        action={
          allowClose ? (<React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={(e) => {
              if (onClose) {
                onClose(e);
              }
            }}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>)
            :
            null
        }
        {...props}
      />
    </div>
  );
}

export default CustomSnackbar;
