import {  Navigate } from "react-router-dom";
function AuthCheck({ children, ...rest }) {
    const user = sessionStorage.getItem('user');
    if(rest.auth){
      return user ? children : <Navigate to="/login" replace />;
    }else{
      return !user ? children : <Navigate to="/searchPartners" replace />;
    }
  }

  export default AuthCheck;
