import React from "react";
import { Grid, Icon } from "@ccb/components/";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  icon: {
    marginBottom: theme.spacing(1.5),
  },
  iconGroup:{
    display:"inline"
  },
  checkCircle:{
    float: "right",
    position: "relative"
  },
  actionBtn: {
    display: "flex",
    flexDirection: 'column',
    backgroundColor: '#87CEEB' ||theme.palette.color.darkBlue,
    borderRadius: 12,
    padding: theme.spacing(2.5, 4),
    color: '#FFFFFF' ||theme.palette.color.white,
    height: '100%',
    minHeight: 100,
  },
  successBtn: {
    display: "flex",
    flexDirection: 'column',
    backgroundColor: '#5A5A5A' ||theme.palette.color.greyDark,
    borderRadius: 12,
    padding: theme.spacing(2.5, 4),
    color: '#FFFFFF' ||theme.palette.color.white,
    height: '100%',
    minHeight: 100,
  },
  sub: {
    fontSize: 8,
    marginTop: theme.spacing(1),
  }
}));

const DashboardCard = (props) => {
  const classes = useStyles();
  const { icon, title, subTitle, type } = props;
  if (type !== 'success') {
    return (
      <Grid className={classes.actionBtn}>
        <Icon name={icon} className={classes.icon} />
        {title}
        <span className={classes.sub}>{subTitle}</span>
      </Grid>
    );
  }
  else {
    return (
      <Grid className={classes.successBtn}>
        <div className={classes.iconGroup}>
          <Icon name={icon} />
          <Icon name={'CheckCircle'} className={classes.checkCircle} />
        </div>
        {title}
        <span className={classes.sub}>{subTitle}</span>
      </Grid>
    );
  }

};

export default DashboardCard;
