import React from "react";
import { useNavigate } from "react-router-dom";
import {
  createStyles,
  makeStyles,
  Menu,
  MenuItem,
  FormControl,
  Select,
} from "@material-ui/core";
import {
  AuthHeader,
  Grid,
  EVTypography,
  Icon,
  IconButtons,
  Toolbar,
  Breadcrumbs,
} from "@ccb/components";
import Axios from "axios";

const useStyles = makeStyles((theme) =>
  createStyles({
    toolbar: {
      background: theme.palette.color.bgBlueLight,
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      padding: theme.spacing(4),
      paddingLeft: 134,
    },
    headerRight: {
      display: "flex",
      flexDirection: "row",
    },
    liveDemoBtn: {
      margin: theme.spacing(0, 2),
      background: theme.palette.color.white,
      minWidth: 98,
      minHeight: 32,
    },
    margin: {
      margin: theme.spacing(0, 1),
      background: "none !important",
    },
    selectLang: {
      paddingRight: theme.spacing(5),
    },
    select: {
      borderRadius: 12,
      background: "#E7E9E8",
      "&:before": {
        display: "none",
      },
      "&:after": {
        display: "none",
      },
      "& > div": {
        padding: theme.spacing(2, 7, 1.5, 2.5),
        fontSize: 14,
      },
    },
    editIcon: {
      marginRight: theme.spacing(3),
    },
  })
);

const Header = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [language, setLanguage] = React.useState("English");

  const UserMenuhandleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const userMenuhandleClose = () => {
    setAnchorEl(null);
  };

  const handleDurationChange = (e) => {
    setLanguage(e.target.value);
  };

  const languageList = ["English", "Telugu", "Hindi", "Tamil"];
  const editHandler = () => {
    navigate(`/partners/managePartner/${props.partnerID}`);
  };
  return (
    <AuthHeader>
      <Toolbar className={classes.toolbar}>
        <Grid>
          {/* <Breadcrumbs list={props.breadCrumbs} /> */}
          <EVTypography variant="h3" className={classes.title}>
            {props.title}
          </EVTypography>
        </Grid>

        <Grid className={classes.headerRight}>
          {props.editIcon && 
          <IconButtons
            aria-label="edit"
            className={classes.editIcon}
            color="inherit"
            size="small"
            onClick={editHandler}
          >
            <Icon name="EditOutlined" />
          </IconButtons>}
          <FormControl
            fullWidth
            variant="filled"
            className={classes.selectLang}
          >
            <Select
              id="language-field"
              value={language}
              onChange={(e) => {
                handleDurationChange(e);
              }}
              className={classes.select}
              MenuProps={{
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
              }}
            >
              {languageList.map((data) => (
                <MenuItem key={data} value={data}>
                  {data}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <IconButtons
            aria-label="plus"
            className={classes.margin}
            color="inherit"
            size="small"
            onClick={UserMenuhandleClick}
          >
            <Icon name="UserPersona_icon" />
          </IconButtons>
          <Menu
            id="user-menu"
            keepMounted
            open={Boolean(anchorEl)}
            onClose={userMenuhandleClose}
            PaperProps={{
              style: {
                width: 150,
              },
            }}
            anchorEl={anchorEl}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            transformOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <MenuItem onClick={() => {
              userMenuhandleClose();
              window.location.replace(`/manageMyAccount`);
            }}>My Account</MenuItem>
            <MenuItem
              onClick={() => {
                Axios.get('/ccbuser/logout').then(() => {
                  sessionStorage.removeItem("user");
                  sessionStorage.removeItem("userPermissions");
                  navigate("/login");
                });
              }}
            >
              Logout
            </MenuItem>
          </Menu>
        </Grid>
      </Toolbar>
    </AuthHeader>
  );
};

export default Header;
