import { Switch as MuiSwitch, Typography } from "@material-ui/core";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 56,
    height: 24,
    padding: 0
  },
  switchBase: {
    padding: 3,
    "&$checked": {
      transform: "translateX(30px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: theme.palette.color.lightBlack,
        opacity: 1,
        border: `1px solid ${theme.palette.color.black}`
      }
    }
  },
  thumb: {
    width: 18,
    height: 18,
    background: theme.palette.color.black,
  },
  track: {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.color.lightBlack,
    opacity: 1,
    border: `1px solid ${theme.palette.color.black}`,
    transition: theme.transitions.create(["background-color", "border"])
  },

  // Don't remove this empty class.
  checked: {},

  switchRoot: {
    display: "flex",
    position: "relative",
    alignItems: "center",
    cursor: "pointer",
    // width: 0
  },
  label: {
    fontSize: 12,
    fontWeight: 400,
    position: "absolute",
    zIndex: 1,
    color: theme.palette.common.black,
    userSelect: "none",
    pointerEvents: "none",
    lineHeight: '17px'
  },
  left: {
    left: 6
  },
  right: {
    left: 27
  }
}));

const CustomSwitchInput = ({ checked, ...props }) => {
  const classes = useStyles();

  return (
    <div className={classes.switchRoot}>
      {checked && (
        <Typography
          variant="subtitle2"
          className={clsx(classes.label, classes.left)}
        >
         {props.onText || 'ON'}
        </Typography>
      )}
      <MuiSwitch
        checked={checked}
        disableRipple
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked
        }}
        {...props}
      />
      {!checked && (
        <Typography
          variant="subtitle2"
          className={clsx(classes.label, classes.right)}
        >
          {props.offText || 'OFF'}
        </Typography>
      )}
    </div>
  );
};

export default CustomSwitchInput;
