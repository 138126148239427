import Axios from "axios";
import { cryptoJSEncryptAES, cryptoJSDecryptAES } from "./utils/helper";
import { APP_API_URL, APP_NODE_API_BASE_URL, REACT_APP_ENV } from './constants';

const ServiceInterceptor = () => {
    const isAbsoluteURLRegex = /^(?:\w+:)\/\//;
    Axios.interceptors.request.use(request => {
        if (!isAbsoluteURLRegex.test(request.url)) {
            request.url = `${APP_API_URL}${request.url}`;
        }
        request.withCredentials = true;
        if (REACT_APP_ENV === 'prod' && request?.data) {
            request.headers.secure = true;
            const encryptedData = cryptoJSEncryptAES(request.data);
            request.data = { data: encryptedData };
        }
        return request;
    });
    Axios.interceptors.response.use(response => {
        if (REACT_APP_ENV === 'prod' && response?.data) {
            const decryptData = cryptoJSDecryptAES(response.data, false);
            return decryptData;
        } else {
            return response?.data || response;
        }
    }, error => {
        console.log(error);
    })

}


export default ServiceInterceptor;
