import {
  FormHelperText,
  FormControl,
  makeStyles,
} from "@material-ui/core";
import TxtField from "@material-ui/core/TextField";
import { Field } from "formik";

const useStyles = makeStyles((theme) => ({
  fullWidth: {
    width: "100%",
  },
  required: {
    color: theme.palette.error.main,
    display: "block",
    marginLeft: 2,
    marginRight: 14,
  },
}));

const RenderTextField = ({
  field, // { name, value, onChange, onBlur }
  ...props
}) => {
  const classes = useStyles();
  return (
    <FormControl className={classes.fullWidth}>
      <TxtField
        {...field}
        {...props}
        variant={props.variant || "outlined"}
        fullWidth
        type="text"
        disabled={props.isdisabled}
        // InputLabelProps={{ shrink: true }}
        value={props.form.values[field.name]}
        onChange={(event) => {
          if (props.fieldOnChange) {
            props.fieldOnChange(
              event.target.name,
              event.target.value,
              props.form?.setFieldValue,
              props.form?.values,
              props.form?.setFieldTouched,
              props.form?.touched,
              props.form?.setFieldError
            );
          }
          if (!props.maxLength || event.target.value.length <= props.maxLength) {
            props.form.setFieldValue(field.name, event.target.value);
            props.form.setFieldTouched(field.name, true);
          }
        }}
        onBlur={(event) => {
          props.form.setFieldValue(field.name, event.target.value);
          props.form.setFieldTouched(field.name, true);
        }}
      />
      <FormHelperText className={classes.required}>
        {props.form.touched[field.name] && props.form.errors[field.name]}
      </FormHelperText>
    </FormControl>
  );
};

const TextField = (props) => {
  return (
    <Field
      {...props}
      name={props.name}
      component={RenderTextField}
      label={props.label}
      type="text"
      id={props.id}
      isdisabled={props.isdisabled}
      required={props.required}
      fieldOnChange={props.fieldOnChange}
    />
  );
};

export default TextField;
