import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { EVTypography, Icon } from "@ccb/components/";
import Tab from "@material-ui/core/Tab";

const useStyles = makeStyles((theme) => ({
  tab: {
    marginRight: theme.spacing(4),
    padding: 0,
    "&.Mui-selected": {
      tabCard: {
        background:'#87CEEB' || theme.palette.color.darkBlue,
      },
    },
  },
  type: {
    fontWeight: 400,
    textTransform: "capitalize",
    color: theme.palette.text.primary,
    paddingBottom: theme.spacing(2),
  },
  tabCard: {
    minHeight: 100,
    width: "100%",
    margin: 0,
    padding: theme.spacing(2, 4),
    textAlign: "left",
  },
  value: {
    color: theme.palette.text.primary,
    fontWeight: 400,
  },
  data: {
    color:'#87CEEB' || theme.palette.color.green,
    textTransform: "capitalize",
    "& svg": {
      fill: '#87CEEB' ||theme.palette.color.green,
    },
    "& span": {
      color: theme.palette.text.primary,
    },
  },
}));

const CustomTabCard = (props) => {
  const classes = useStyles();
  const { type, value, percentage, day } = props.data;
  return (
    <div className={classes.tabCard}>
      {type && (
        <EVTypography variant="h5" className={classes.type} color="primary">
          {type}
        </EVTypography>
      )}
      {value && (
        <EVTypography variant="h2" className={classes.value} color="primary">
          {value}
        </EVTypography>
      )}
      {percentage && (
        <EVTypography variant="body1" className={classes.data} color="primary">
          <Icon name="ArrowDown" />
          {percentage} <span>{day}</span>
        </EVTypography>
      )}
    </div>
  );
};

export default CustomTabCard;
