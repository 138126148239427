import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { EVTypography } from '@ccb/components/';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => (
    {
        title:{
            fontWeight: 400,
            backgroundColor: theme.palette.background.paper,
            marginTop: `-${theme.spacing(3)}px`,
            width: "fit-content",
            marginLeft: theme.spacing(8),
            padding: theme.spacing(0, 1),
        },
        cardBoarder : {
            backgroundColor: theme.palette.background.paper,
            border: "1px solid",
            borderColor:theme.palette.primary.main,
            borderRadius: 30,
            minHeight: 150,
            width: "auto",
            margin: 10
        }
    }))

const Card = (props) => {
    const classes = useStyles();

    return (
        <div>
            <div className={classes.cardBoarder}>
                {props.title && <EVTypography variant="h3" className={classes.title} color="primary">{props.title}</EVTypography>}
                {props.children}
            </div>
        </div>
    )
}

Card.prototypes = {
    title: PropTypes.string,
    children: PropTypes.element
}

export default Card;