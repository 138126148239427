import React from "react";
import { Grid, EVTypography, Image } from "@ccb/components/";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  alignCenter: {
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    "& p": {
      margin: "0 auto",
    },
  },
  image: {
    width:"60%",
    textAlign:"center",
    margin:"auto",
    paddingBottom: theme.spacing(5),
  },
  title: {
    marginBottom: theme.spacing(5),
  }
}));

const ContentCard = (props) => {
  const classes = useStyles();
  const {
    mainTitle,
    title,
    description,
    imageAltText,
    imageUrl,
    alignCenter,
  } = props;
  return (
    <Grid className={alignCenter ? classes.alignCenter : null}>
      {mainTitle ? (
        <EVTypography variant="h1" gutterBottom color="primary">
          {mainTitle}
        </EVTypography>
      ) : null}
      {imageUrl ? (
        <Image alt={imageAltText} src={imageUrl} className={classes.image} />
      ) : null}
      {title ? (
        <EVTypography
          variant="h3"
          gutterBottom
          color="primary"
          className={classes.title}
        >
          {title}
        </EVTypography>
      ) : null}
      <EVTypography variant="body1" gutterBottom>
        {description}
      </EVTypography>
    </Grid>
  );
};

export default ContentCard;
