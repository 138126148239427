import { Button } from '@material-ui/core';
import { createStyles, makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles((theme) => createStyles({
    iconBtn: {
        minWidth: "auto",
        width: 38,
        height: 35,
        padding: theme.spacing(3),
        '& svg path': {
            fill: theme?.palette?.color?.white || '#fff',
        }
    }
}));

const EVButton = (props) => {
    const { children, className } = props;
    const classes = useStyles();
    return (
        <Button {...props} className={clsx(className && className, props.type === "iconBtn" && classes.iconBtn)}>
            {children}
        </Button>
    )
}

export default EVButton;
