import {
    TextField,
    FormHelperText,
    FormControl,
    makeStyles,
  } from "@material-ui/core";
import { Field } from "formik";

const useStyles = makeStyles((theme) => ({
    fullWidth: {
        width: "100%",
    },
    required: {
        color: theme.palette.error.main,
        display: "block",
        marginLeft: 2,
        marginRight: 14,
    },
}));

const RenderDateField = ({
    field, // { name, value, onChange, onBlur }
    ...props
}) => {
    const classes = useStyles();
    const today = new Date();
    const formattedToday = today.toISOString().split('T')[0];
    return <FormControl className={classes.fullWidth}>
        <TextField
            {...field}
            {...props}
            variant={props.variant || "outlined"}
            fullWidth
            type="date"
            format="dd-MM-yyyy" 
            InputLabelProps={{ shrink: true }}
            disabled={props.isdisabled}
            value={props.form.values[field.name] || (props.isdisabled ? formattedToday : "") }
            onChange={(event) => {
                const inputJoiningDate = event.target.value;
                if(inputJoiningDate.length <=10){
                    props.form.setFieldValue(field.name, inputJoiningDate);
                } 
            }}
            onBlur={(event) => {
                props.form.setFieldValue(field.name, event.target.value);
            }}
            InputProps={{
                inputProps: {
                  min: props.disablePast?new Date().toISOString().split('T')[0]:"",
                  max: props.disableFuture?new Date().toISOString().split('T')[0]:"",
                },  
              }}
        />
        <FormHelperText className={classes.required}>
            {props.form.touched[field.name] && props.form.errors[field.name]}
        </FormHelperText>
    </FormControl>
}

const DateField = (props) => {
    return (
        <Field
            name={props.name}
            /* component={props.isMask ? RenderEmailField : RenderDateField} */
            component={RenderDateField}
            label={props.label}
            type="text"
            //   id={props.fieldID}
            isdisabled={props.isdisabled}
            disablePast={props.disablePast}
            disableFuture={props.disableFuture}
            value={props.value}
        />
    )
}

export default DateField;
