import { useLocation, useNavigate } from "react-router-dom";
import { Grid, Icon, SideNav as SideNavComponent } from "@ccb/components/";
import {
  makeStyles,
  List,
  ListItem,
  ListItemIcon,
  Link,
  Drawer,
} from "@material-ui/core";
import { alpha } from '@material-ui/core/styles/colorManipulator';
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 118,
    textAlign: "center",
    padding: theme.spacing(7, 0, 4, 0),
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    height: "100%",
    overflowX: "hidden",
    background: theme.palette.secondary.main,
  },
  paperroot: {
    borderRadius: 0,
  },
  navList: {
  },
  linkButton: {
    padding: theme.spacing(3, 2),
    alignItems: "center",
    justifyContent: "center",
    "& span": {
      color: theme.palette.color.white,
      display: "block",
      paddingTop: theme.spacing(2.5),
    },
    "&:hover, &.active": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  listIcon: {
    padding: 0,
    minWidth: "inherit",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    flexShrink: "inherit",
    textAlign: "center",
  },
  btnBottom: {
    background: theme.palette.color.bgBlueLight,
    width: 37,
    height: 37,
    marginTop: theme.spacing(3),
  },
  sideNavBottom: {
    display: "flex",
    // alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  buEntryPartner: {
    background: alpha(theme.palette.color.white, 0.25),
    fontSize: 11,
    color: alpha(theme.palette.color.white, 0.6),
    padding: theme.spacing(2),
  },
  themeButton: {
    padding: 0,
    width: 27,
    minWidth: "inherit",
    margin: theme.spacing(0, 2),
    background: theme.palette.color.bgBlueLight,
    borderRadius: 14,
    "&:hover": {
      background: theme.palette.color.bgBlueLight,
    },
    "& span": {
      display: "flex",
      flexDirection: "column",
      "& small": {
        width: 25,
        height: 25,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "50%",
      },
    },
  },
  themeActiveState: {
    background: theme.palette.primary.light,
  },
  spacing: {
    marginBottom: theme.spacing(1),
  },
  logo: {
    marginBottom: theme.spacing(8),
    '& svg': {
      width:32,
      '& path': {
        fill: theme.palette.color.white,
      }
    }
  }
}));

const SideNav = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const currentURL = useLocation();
  return (
    <SideNavComponent>
      <Drawer
        variant="permanent"
        open
        anchor="left"
        PaperProps={{
          classes: {
            root: classes.paperroot,
          },
        }}
      >
        <Grid className={classes.root}>
          <Grid className={classes.sideNavTop}>
            <Grid className={classes.logo}>
              <Link onClick={() => navigate("/login")} className={classes.logo}>
                <Icon name="EvergentLogoWhite" />
              </Link>
            </Grid>
            <List component="nav" className={classes.navList}>
              {props.navList.map((item, i) => (item.enabled && (
                <ListItem
                  button
                  key={i}
                  className={clsx(classes.linkButton,  currentURL.pathname===item.path ? "active" : '')}
                  onClick={() => navigate(`${item.path}`)}
                >
                  <ListItemIcon className={classes.listIcon}>
                    <Icon name={item.icon} />
                    <span>{item.title}</span>
                  </ListItemIcon>
                </ListItem>
              )))}
            </List>
          </Grid>
{/*           <Grid>
            <Grid className={classes.sideNavBottom}>
              <Link href="/searchPartners" className={classes.buEntryPartner}>Business Development Manager</Link>
            </Grid>
          </Grid> */}
        </Grid>
      </Drawer>
    </SideNavComponent>
  );
};

export default SideNav;
