import React from "react";
import MUIDataTable from "mui-datatables";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { Icon, Grid } from "@ccb/components";
import CircularProgress from "@material-ui/core/CircularProgress";
import evergentTheme from "../AppTheme/AppTheme";
import clsx from "clsx";

const DynamicTable = (props) => {
  const [tableOptions, setTableOptions] = React.useState({});
  const [theme, setTheme] = React.useState({});

  const SearchIcon = () => <Icon name="TableSearch" />;

  const FilterIcon = () => <Icon name="TableFilter" />;

  const ViewColumnIcon = () => <Icon name="ViewTableCols" />;

  const DownloadIcon = () => <Icon name="TableDownload" />;

  const PrintIcon = () => <Icon name="TablePrint" />;

  const dataTablePropComponents = {
    icons: {
      SearchIcon,
      PrintIcon,
      DownloadIcon,
      FilterIcon,
      ViewColumnIcon,
    },
  };

  React.useEffect(() => {
    const getMuiTheme = () =>
      createTheme({
        overrides: {
          MUIDataTableToolbar: {
            root: {
              padding: "0",
              minHeight: "34px",
              "& button": {
                color: evergentTheme.palette.color.textColor,
                padding: "4px",
                fontSize: "11.25pt",
                marginLeft: "10px",
                borderRadius: "4px",
              },
            },
            actions: {
              position: "relative",
              zIndex: "2",
              flex: "inherit",
            },
            left: {
              position: "relative",
              zIndex: "2",
            },

            // root: {
            //   padding: '0 !important',
            // },
            filterPaper: {
              padding: "0 !important",
              width: "400px !important",
            },
            icon: {
              MuiSvgIcon: {
                root: {
                  color: evergentTheme.palette.color.textColor,
                },
              },
            },
            titleText: {
              fontSize: "9pt",
              color: evergentTheme.palette.color.textColor,
              fontWeight: "400",
              textTransform: "uppercase",
              fontFamily: evergentTheme.typography.fontFamily,
              letterSpacing: "1px",
            },
          },
          MUIDataTableSearch: {
            searchIcon: {
              color: evergentTheme.palette.color.textColor,
            },
          },
          MuiInputBase: {
            root: {
              margin: "0",
            },
            input: {
              color: evergentTheme.palette.color.textColor,
            },
          },
          MUIDataTableToolbarSelect: {
            root: {
              visibility: props.customToolbar ? "initial" : "hidden",
              display: props.customToolbar ? "none" : "block",
              "& > div": {
                display: "inline-block",
              },
              "& > button": {
                display: "none",
              },
            },
          },
          MUIDataTableHeadCell: {
            root: {
              fontSize: "9.75pt",
              color: evergentTheme.palette.color.textColor,
              fontWeight: "600 !important",
              fontFamily: evergentTheme.typography.fontFamily,
              border: "0",
              lineHeight: "16px",
              // whiteSpace:'pre'
            },
            data: {
              fontWeight: "600",
              fontFamily: evergentTheme.typography.fontFamily,
              lineHeight: "20px",
              fontSize: "9.75pt",
            },
            toolButton: {
              height: "auto",
              alignItems: "center",
            },
            sortActive: {
              color: evergentTheme.palette.color.textColor,
            },
            sortAction: {
              alignItems: "center",
            },
            fixedHeaderCommon: {
              backgroundColor: "transparent",
            },
          },
          MuiTableRow: {
            root: {
              MUIDataTableBodyCell: {
                responsiveStacked: {
                  fontWeight: "600",
                  backgroundColor: "green",
                },
              },
            },
          },
          MUIDataTableBody: {
            emptyTitle: {
              fontSize: "9.75pt",
              padding: "15px",
            },
          },
          MuiTableCell: {
            root: {
              padding: "10px",
              borderBottom: "none",
            },
            body: {
              fontSize: "9.75pt",
              color: evergentTheme.palette.color.textColor,
              fontWeight: "400",
              fontFamily: evergentTheme.typography.fontFamily,
            },
          },
          MuiButton: {
            root: {
              fontFamily: evergentTheme.typography.fontFamily,
              textTransform: 'inherit',
              lineHeight: 1.5,
              fontSize: 14,
            },
            text: {
              paddingTop: 0,
              paddingBottom: 0,
            },
            outlinedPrimary: {
              color: evergentTheme.palette.primary.main,
              fontFamily: evergentTheme.typography.fontFamily,
            }
          },
          MuiTableBody: {
            root: {
              "& tr:nth-child(even)": {
                backgroundColor: "transparent",
                border: "0",
              },
              "& tr:nth-child(odd)": {
                backgroundColor: "#f6f6fa",
                border: "0",
              },
              "& td": {
                border: "0",
              },
            },
          },
          MUIDataTablePagination: {
            root: {
              padding: "0 !important",
              color: `${evergentTheme.palette.color.textColor} !important`,
              fontSize: "10.5pt",
              fontWeight: "400",
              fontFamily: evergentTheme.typography.fontFamily,
              border: "0",
            },
          },
          MUIDataTableViewCol: {
            title: {
              color: evergentTheme.palette.color.textColor,
              fontWeight: 500,
            },
            label: {
              color: evergentTheme.palette.color.textColor,
            },
          },
          MuiInputLabel: {
            formControl: {
              color: evergentTheme.palette.color.textColor,
              fontWeight: "300",
              fontSize: "13px",
              paddingRight: "20px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              width: "100%",
              lineHeight: "14px",
            },
          },
          MUIDataTableFilter: {
            selectRoot: {
              marginTop: "5px",
            },
            title: {
              margin: "0 !important",
              color: evergentTheme.palette.color.textColor,
            },
          },
          MuiMenuItem: {
            root: {
              paddingLeft: "10px",
            },
          },
          MuiSelect: {
            select: {
              color: evergentTheme.palette.color.textColor,

              fontSize: "16px",
              fontWeight: "500",
            },
            icon: {
              color: evergentTheme.palette.color.textColor,
            },
          },
          MuiListItemText: {
            primary: {
              fontSize: "9.75pt",
              color: evergentTheme.palette.color.textColor,

              fontWeight: 400,
            },
          },
          MuiTableSortLabel: {
            root: {
              color: `${evergentTheme.palette.color.textColor} !important`,
              width: "16px",
            },
            icon: {
              color: `${evergentTheme.palette.color.textColor} !important`,
              fontSize: "16px",
            },
            active: {
              color: `${evergentTheme.palette.color.textColor} !important`,
            },
          },
          MuiGridList: {
            root: {
              margin: "-8px !important",
            },
          },
          MuiGridListTile: {
            root: {
              padding: "8px !important",
            },
          },

          MuiTablePagination: {
            root: {
              padding: "0 !important",
              color: evergentTheme.palette.color.textColor,
              fontSize: "10.5pt",
              fontWeight: "400",
              fontFamily: evergentTheme.typography.fontFamily,
              border: "0",
            },
            caption: {
              fontSize: "9.75pt",
              fontFamily: evergentTheme.typography.fontFamily,
            },
            select: {
              fontSize: "9.75pt",
              color: evergentTheme.palette.color.textColor,
            },
            selectIcon: {
              color: evergentTheme.palette.color.textColor,
            },
            toolbar: {
              paddingLeft: 0,
              fontSize: "10.5pt",
              "& button": {
                color: evergentTheme.palette.color.textColor,
                padding: "4px",
                fontSize: "11.25pt",
                marginLeft: "10px",
                borderRadius: "4px",
              },
              "& button:disabled": {
                color: "#ddd",
              },
            },
            spacer: {
              flexGrow: "0",
              flexShrink: "0",
              flexBasis: "0",
            },
            actions: {
              flexGrow: "1",
              flexShrink: "1",
              flexBasis: "100%",
              display: "flex",
              justifyContent: "flex-end",
            },
          },
          MUIDataTableSelectCell: {
            headerCell: {
              backgroundColor: "transparent",
            },
            fixedHeaderCommon: {
              backgroundColor: "transparent",
            },
            expandDisabled: {
              visibility: "hidden",
            },
          },
        },
        MuiTypography: {
          root: {
            fontFamily: evergentTheme.typography.fontFamily,
          },
        },
        typography: {
          useNextVariants: true,
        },
        palette: {
          primary: {
            light: evergentTheme.palette.primary.light,
            main: evergentTheme.palette.primary.main,
            dark: evergentTheme.palette.primary.dark,
            contrastText: "#fff",
          },
          secondary: {
            light: evergentTheme.palette.secondary.main,
            main: evergentTheme.palette.secondary.main,
            dark: evergentTheme.palette.secondary.main,
            contrastText: "#fff",
          },
        },
      });
    setTheme(getMuiTheme());
  }, []);

  const filterClass = props.data.length > 0;

  React.useEffect(() => {
    const selectableRows = props.options.selectableRows || "none";
    const rowsPerPage = props.options.rowsPerPage || 10;
    const options = {
      /* setRowProps: func - Useful when we have something with row like adding classes to tr tag. */
      ...props.options,
      selectableRows,
      selectToolbarPlacement: "none",
      fixedHeader: true,
      rowsPerPage,
      filterType: "multiselect",
      downloadOptions: {
        filename:
          props.options.downloadOptions &&
          props.options.downloadOptions.filename
            ? props.options.downloadOptions.filename
            : "tableDownload.csv",
        separator: ",",
      },
      selectableRowsOnClick: false,
      download: Object.prototype.hasOwnProperty.call(props, "downloadable")
        ? props.data.length > 0
          ? !!props.downloadable
          : false
        : false,
      filter: Object.prototype.hasOwnProperty.call(props, "hideFilter")
        ? !props.hideFilter
        : Boolean(props.data.length > 0),
      search: Object.prototype.hasOwnProperty.call(props, "hideSearch")
        ? !props.hideSearch
        : Boolean(props.data.length > 0),
      viewColumns: Object.prototype.hasOwnProperty.call(
        props,
        "hideViewColumns"
      )
        ? !props.hideViewColumns
        : Boolean(props.data.length > 0),
      print: false,
      fixedSelectColumn: false,
      pagination: Object.prototype.hasOwnProperty.call(props, "hidePagination")
        ? !props.hidePagination
        : Boolean(props.data.length > 0),
      textLabels: {
        body: {
          noMatch: props.loading ? (
            <CircularProgress className="circular_progress" color="primary" />
          ) : (
            props.noDataText
          ),
        },
      },
      elevation: 0,
    };
    setTableOptions(options);
  }, [props, props.options]);

  return (
    <Grid
      className={clsx(
        props.options.onRowClick && props.data.length > 0 ? "showCursor" : "",
        filterClass ? "" : "noFilters",
        props.className)}
    >
      <MuiThemeProvider theme={theme}>
        <MUIDataTable
          columns={props.columns}
          options={tableOptions}
          data={props.data}
          title={props.title}
          components={dataTablePropComponents}
        />
      </MuiThemeProvider>
    </Grid>
  );
};

export default DynamicTable;
