import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";

const useStyles = makeStyles((theme) => ({
    chip: {
        height: "auto",
        padding: theme.spacing(1, 3),
        "& span": {
            fontSize: 10,
            textTransform: "uppercase",
            padding: 0,
            color: theme.palette.color.white,
        }
    }
}));

const Status = (props) => {
    const classes = useStyles();
    return (
        <Chip color={props.color} label={props.label} className={classes.chip} />
    )
}

export default Status;