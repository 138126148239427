import Paper from '@material-ui/core/Paper';
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  paperWrap: {
    borderRadius: 14,
    padding: theme.spacing(5),
  },
}));

const EVPaper = (props) => {
  const { children, elevation, customClass } = props;
  const classes = useStyles();

  return (
    <Paper elevation={elevation || 1} className={clsx(classes.paperWrap, customClass && customClass)} {...props}>
      {children}
    </Paper>
  );
};

export default EVPaper;
