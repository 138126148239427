import {
  FormHelperText,
  FormControl,
  makeStyles,
} from "@material-ui/core";
import InputAdornment from '@material-ui/core/InputAdornment';
import TxtField from "@material-ui/core/TextField";
import { Field } from "formik";

const useStyles = makeStyles((theme) => ({
  fullWidth: {
    width: "100%",
  },
  required: {
    color: theme.palette.error.main,
    display: "block",
    marginLeft: 2,
    marginRight: 14,
  },
}));

const RenderTextField = ({
  field, // { name, value, onChange, onBlur }
  ...props
}) => {
  const classes = useStyles();
  return (
    <FormControl className={classes.fullWidth}>
      <TxtField
        {...field}
        {...props}
        variant={props.variant || "outlined"}
        fullWidth
        type="text"
        disabled={props.isdisabled}
        value={props.form.values[field.name]}
        InputProps={{
                startAdornment: <InputAdornment position={props.adornmentPosition}>{props.dialingCode}</InputAdornment>,
                endAdornment: <InputAdornment position="end">{props.endAdornmentJSX}</InputAdornment>,
        }}
        onChange={(event) => {
          if(!isNaN(event.target.value) || !event.target.value.length ){
            if (props.maxLength) {
              if (!event.target.value || event.target.value.toString().length <= props.maxLength) {
                props.form.setFieldValue(field.name, event.target.value);
                props.form.setFieldTouched(field.name, true);
              }
            }
            else {
              props.form.setFieldValue(field.name, event.target.value);
              props.form.setFieldTouched(field.name, true);
            }
          }
        }}
        onBlur={(event) => {
          props.form.setFieldValue(field.name, event.target.value);
          props.form.setFieldTouched(field.name, true);
        }}
      />
      <FormHelperText className={classes.required}>
        {props.form.touched[field.name] && props.form.errors[field.name]}
      </FormHelperText>
    </FormControl>
  );
};

const PhoneNumberInput = (props) => {
  return (
    <Field
      {...props}
      name={props.name}
      component={RenderTextField}
      label={props.label}
      type="text"
      id={props.id}
      isdisabled={props.isdisabled}
	  adornmentValue={props.dialingCode}
	  adornmentPosition={props.adornmentPosition}
    endAdornmentJSX={props.endAdornmentJSX || <></>}
    required={props.required}
	  onKeyDown={(event) => {
        if (props.allowDecimals === false) {
          // If the input is a decimal and allowDecimals is false then prevent it
          if (event.keyCode === 190) {
            event.preventDefault();
          }
        }
      }}
    />
  );
};

PhoneNumberInput.defaultProps = {
  name: "",
  label: "",
  id: "",
  adornmentPosition: "start",
  endAdornmentJSX: <></>,
  dialingCode: '',
  allowDecimals: true,
};

export default PhoneNumberInput;
