import React, { Suspense, useState, useEffect } from "react";
import axios from "axios";
import { ThemeProvider } from "@material-ui/core";
import RouteWrapper from "./routes/RouteWrapper";
import {
  CustomSnackbar,
  Loader as LoaderComponent,
} from "@ccb/components";
import AppTheme from "src/components/AppTheme/AppTheme";
import { APP_API_URL } from './constants';
import "./App.css";
import ServiceInterceptor from "./ServiceInterceptor";
import AppContext from "./AppContext";
import { ApolloProvider } from "@apollo/client";
import client from "./apollo/clients";
import ReactGA from "react-ga";
const TRACKING_ID = "UA-243666262-1"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function App() {
  const [alertMessage, setAlertMessage] = useState(null);
  const [Loader, showLoading] = useState(false);
  const [partnerRolesList,setPartnerRolesList] = useState([]);
  let userPermissions = sessionStorage.getItem('userPermissions');
  const userinfo = sessionStorage.getItem("user");

  ServiceInterceptor();

  const getPolicyDetails_apiCall = async (policyId) => {
    return await axios.post(`${APP_API_URL}/prtnermgmt/getPolicyDetails`, {
      GetPolicyTabDetailsRequestMessage: {
        policyId
      },
    })
      .then((apiResponse) => {
        if (apiResponse.GetPolicyTabDetailsResponseMessage.responseCode === "1") {
          (apiResponse.GetPolicyTabDetailsResponseMessage.modules).forEach(module => {
            if (module.applicationName === 'Partner Management') {
              userPermissions = module;
              sessionStorage.setItem('userPermissions', JSON.stringify(module));
              window.location.reload();
            }
          });

        } else {
          userPermissions = false;
          // appContext.setAlertMessage(apiResponse.GetPolicyTabDetailsResponseMessage.failureMessage[0].errorMessage)
          throw apiResponse.GetPolicyTabDetailsResponseMessage.failureMessage[0].errorMessage;
        }
      })
  };

  useEffect(() => {
    if (userPermissions === null && userinfo !== null) {
      getPolicyDetails_apiCall(JSON.parse(userinfo)?.policyID);
    }
  }, [userPermissions, userinfo]);

  useEffect(() => {
    getPartnersList_apiCall();
    (async () => {
      axios.interceptors.request.use(
        (config) => {
          if (typeof config.hideLoader === "undefined") {
            showLoading(true);
          }
          // sendLogsRequestResponseLogsToServer(config, 'Request');
          return config;
        },
        (error) => Promise.reject(error)
      );

      // Add a response interceptor
      axios.interceptors.response.use(
        (response) => {
          showLoading(false);
          return response;
        },
        (error) => {
          // Do something with response error
          showLoading(false);
          return Promise.reject(error);
        }
      );
    })();
  }, []);


  const getPartnersList_apiCall = async () => {
      await axios.post("/masterData/loadPartnerTypesList", {
  
      })
        .then((apiResponse) => {
          const partnerRolesList = apiResponse.map((partnerRole) => {
            return {
              value: partnerRole?.displayName,
              label: partnerRole?.displayName,
              multiParentAllowed: partnerRole?.multiParentAllowed,
              hasParentPartner: partnerRole?.hasParentPartner,
              retailerInstaller: partnerRole?.retailerInstaller,
              apiValue:partnerRole?.description,
            };
          });
          setPartnerRolesList(partnerRolesList);
        })
        .catch((caughtError) => {
          console.log("error", caughtError);
        });
    };

  return (
    <ApolloProvider client={client}>
      <AppContext.Provider
        value={{
          alertMessage,
          setAlertMessage,
          Loader,
          showLoading,
          partnerRolesList,
          setPartnerRolesList
        }}
      >
        {Loader && <LoaderComponent />}

        <ThemeProvider theme={AppTheme}>
          <Suspense fallback={<div>loading...</div>}>
            <RouteWrapper />
          </Suspense>
        </ThemeProvider>
        <CustomSnackbar
          vertical={"top"}
          horizontal={"right"}
          message={alertMessage}
          open={!!alertMessage}
          onClose={() => {
            setAlertMessage(null);
          }}
          allowClose
          serviceType="error"
        />
      </AppContext.Provider>
    </ApolloProvider>
  );
}

export default App;
