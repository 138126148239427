import React from "react";
import Card from "@material-ui/core/Card";
import { makeStyles } from "@material-ui/core";
import { EVTypography, Grid, Button, Icon, IconButtons } from "@ccb/components";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  card: {
    background: '#87CEEB' ||theme.palette.color.white,
    borderRadius: 12,
    padding: theme.spacing(3),
    border: '#87CEEB' ||`1px solid ${theme.palette.color.darkBlue}`,
    cursor: "pointer",
    "& img": {
      borderRadius: 8,
    },
  },
  disable: {
    background: '#87CEEB' ||theme.palette.color.bgBlueLight,
    border: "none",
    pointerEvents: "none",
  },
  btntestRevoke: {
    width: 65,
    minWidth: "inherit",
  },
  buActions: {
    "& button": {
      "& svg": {
        "& path": {
          fill:'#87CEEB' || theme.palette.color.darkBlue,
        },
      },
    },
  },
  logo: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },
}));

const PaymentGatewayCard = (props) => {
  const { cardType, cardLogo, cardValidDate, cardEnable } = props;
  const classes = useStyles();
  return (
    <Grid>
      <Card elevation={0} className={clsx(classes.card, !cardEnable && classes.disable)}>
        <Grid container>
          <Grid item md={6}>
            <EVTypography variant="body2" gutterBottom>
              {cardType}
            </EVTypography>
          </Grid>
          <Grid item md={6}>
            <Grid className={classes.logo}>
              <img src={cardLogo} alt="icons" />
            </Grid>
          </Grid>
        </Grid>
        <EVTypography variant="caption" display="block" gutterBottom>
          {cardValidDate}
        </EVTypography>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={3}
        >
          {cardEnable ? (
            <>
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  className={classes.btntestRevoke}
                >
                  Test
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  className={classes.btntestRevoke}
                >
                  Revoke
                </Button>
              </Grid>
            </>
          ) : (
            <>
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  fullWidth
                  disabled
                >
                  Disabled
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </Card>
      <Grid className={classes.buActions}>
        <IconButtons
          aria-label="more"
          onClick={(e) => console.log("card icon click")}
          className={classes.menuIcon}
        >
          {cardEnable ? (
            <Icon name="CreditCardOff" />
          ) : (
            <Icon name="CreditScore" />
          )}
        </IconButtons>
        <IconButtons
          aria-label="more"
          onClick={(e) => console.log("delete action")}
          className={classes.menuIcon}
        >
          <Icon name="Delete" />
        </IconButtons>
      </Grid>
    </Grid>
  );
};

export default PaymentGatewayCard;
