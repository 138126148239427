import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => {
    return {
        root: {
            "& > *": {
                margin: theme.spacing(4),
                padding: theme.spacing(4),
                width: theme.spacing(150),
                height: theme.spacing(28),
            },
        },

        customBorderRadius: {
            borderRadius: 6,
        },
    };
});

const EvMUIPaper = (props) => {
  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      <Paper>
        {props.children}
      </Paper>
    </div>
  );
};

EvMUIPaper.defaultProps= {
    children: ()=>{
    return <Typography variant="h5">This is default typography component</Typography>
  }
}

export default EvMUIPaper;
