import React from "react";
import { CssBaseline } from "@material-ui/core";


const AuthLayout = (props) => {
  const { children } = props;
  return (
    <>
      <CssBaseline />
      <props.container>
        {props.sideNav ? <props.sideNav /> : null}
        {props.header ? <props.header {...props} /> : null}
        {props.mainContent ? (
          <props.mainContent {...props}>{children}</props.mainContent>
        ) : null}
      </props.container>
    </>
  );
};

AuthLayout.defaultProps = {
  sideNav: () => {
    <></>;
  },
  header: () => {
    <></>;
  },
};

export default AuthLayout;
